
import {BusinessRules} from './BusinessRulesV2';
import {DCOtoglTF} from './DCOtoglTF';

//
// TODO: We need a config option or something for Develop / Production builds.

//const URL_SPIN_SERVER = 'http://127.0.0.1:8000/';
const URL_SPIN_SERVER = 'https://devjobservicesondemand.marxent.io/';
//const URL_SPIN_SERVER = 'https://jobservicesondemand.marxent.io/';

// Get product from url parameter
var url = window.location.href;

if(url.indexOf("sku")==-1)
{
    window.location.href = url + "?sku=MIWKWNOTTTN2HNM20PNS009";
}

var productID = url.split('?sku=')[1];

// console.log("productID:" + productID);

let passedInProductID = url.split('?sku=')[1];;
passedInProductID = passedInProductID.replace("&sku2=","");
// console.log("passedinproductid:" + passedInProductID);
let suspensionID = -1;
const suspensionKey = "_SUSPENSION";
const suspensionLoc = passedInProductID.indexOf(suspensionKey);

if(suspensionLoc>-1)
{
    const suspensionText = passedInProductID.substring(suspensionLoc+suspensionKey.length);
    suspensionID = parseInt(suspensionText);
    passedInProductID = passedInProductID.substring(0,suspensionLoc);
    // console.log("suspension id:" + suspensionID);
}

productID = productID.slice(0,4); // Ensure only FLC3 for now - will take full SKU and set defaults based on SKU later

const theConverter = new DCOtoglTF();
const br = new BusinessRules(URL_SPIN_SERVER, productID, enqueueProductToSpinsServer, passedInProductID, suspensionID, theConverter);

function enqueueProductToSpinsServer (theSKU, theProductID, theDefaultChildOverrides) {
    
    if (theConverter != null) {
        // console.log('Enqueuing product: ' + theSKU + ':' + theProductID + ':' + theDefaultChildOverrides);
        theConverter.renderglTF(theProductID, theDefaultChildOverrides, br.fieldOfView, br.cameraHeightOffset, br.cameraHeightOffsetForAngle, br.cameraDepthOffset);
    }
}
