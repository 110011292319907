
import {MxtglTF} from './mxt-gltf';
import LoadingIcon from './../assets/images/icons/loader.svg';

export class DCOtoglTFTables {
    
    constructor(mxtglTFArray) {
        
        this.mxtglTFArray = mxtglTFArray;

        //
        // TODO: This needs to be configurable.
        //this.urlBase = 'https://s3.amazonaws.com/vc-gltf/develop/000030/';
        //this.urlBase = 'https://s3.amazonaws.com/vc-gltf/staging/000030/';
        this.urlBase = 'https://s3.amazonaws.com/vc-gltf/production/000030/';

        this.urlMaterialsFrame       = this.urlBase + 'materials/Materials_Frame/Materials_Frame.gltf';
        this.urlMaterialsShelfDrawer = this.urlBase + 'materials/Materials_Shelf/Materials_Shelf.gltf';

        this.productIDsToglTFs = { };

        //
        // Side Tables

        this.productIDsToglTFs['HRMALYS-28Z14Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_SideTable_28x14x16/Geiger_SideTable_28x14x16.gltf';
        this.productIDsToglTFs['HRMALYS-28Z22Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_SideTable_28x22x16/Geiger_SideTable_28x22x16.gltf';
        this.productIDsToglTFs['HRMALYD-28Z22Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_SideTable_28x22x16/Geiger_SideTable_28x22x16.gltf';

        //
        // Coffee Tables

        this.productIDsToglTFs['HRMALYS-46Z24Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_CoffeeTable_46x24x16/Geiger_CoffeeTable_46x24x16.gltf';
        this.productIDsToglTFs['HRMALYD-46Z24Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_CoffeeTable_46x24x16/Geiger_CoffeeTable_46x24x16.gltf';
        this.productIDsToglTFs['HRMALYS-66Z28Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_CoffeeTable_66x28x16/Geiger_CoffeeTable_66x28x16.gltf';
        this.productIDsToglTFs['HRMALYD-66Z28Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_CoffeeTable_66x28x16/Geiger_CoffeeTable_66x28x16.gltf';

        //
        // Nesting Tables

        this.productIDsToglTFs['HRMALNHS-66Z28Z16Z-FRAME'] = this.urlBase + 'tables/Geiger_NestingTable_66x28x16/Geiger_NestingTable_66x28x16.gltf';
        this.productIDsToglTFs['HRMALNHS-66Z28Z16Z-FRAME-CENTERED'] = this.urlBase + 'tables/Geiger_NestingTable_66x28x16_Centered/Geiger_NestingTable_66x28x16_Centered.gltf';
    }

    _loadTable(mxtglTF, urlTable, theDCOs, dcoTableFramePrimary, dcoTableFrameSecondary) {
        return new Promise((resolve, reject)=> {
            mxtglTF.loadTable(urlTable, this.urlMaterialsFrame, this.urlMaterialsShelfDrawer, () => {

                const dcoTableFrameCover = this._findDCOByStepType('Table Frame Cover', theDCOs);
                const dcoShelfCover = this._findDCOByStepType('Shelf Cover', theDCOs);
                const dcoShelfPrimary = this._findDCOByStepType('Shelf Primary', theDCOs);
                const dcoTableTopCover = this._findDCOByStepType('Table Top Cover', theDCOs);
                
                if (dcoShelfPrimary != null && dcoShelfPrimary.productID == 'remove' && dcoTableFramePrimary.productID.indexOf("HRMALNHS")==-1) {

                    mxtglTF.setVisibilityTableFramePrimary(true);
                    mxtglTF.setVisibilityTableFrameSecondary(false);
                    mxtglTF.setVisibilityTableShelfPrimary(false);
                    mxtglTF.setVisibilityTableDrawer(false);
                    mxtglTF.setVisibilityTableBracketsPrimary(false);
                    mxtglTF.setVisibilityTableBracketsSecondary(false);

                } else {

                    if(dcoTableFramePrimary.productID.indexOf("HRMALYD")>-1)
                    {

                        if(dcoTableFramePrimary.productID.indexOf("66Z28Z16Z")>-1)
                        {

                            mxtglTF.setVisibilityTableFramePrimary(false);
                            mxtglTF.setVisibilityTableFrameSecondary(true);
                            mxtglTF.setVisibilityTableShelfPrimary(false);
                            mxtglTF.setVisibilityTableDrawer(true);
                            mxtglTF.setVisibilityTableBracketsPrimary(false);
                            mxtglTF.setVisibilityTableBracketsSecondary(true);        
                        }
                        else
                        {
                            mxtglTF.setVisibilityTableFramePrimary(true);
                            mxtglTF.setVisibilityTableFrameSecondary(false);
                            mxtglTF.setVisibilityTableShelfPrimary(false);
                            mxtglTF.setVisibilityTableDrawer(true);
                            mxtglTF.setVisibilityTableBracketsPrimary(true);
                            mxtglTF.setVisibilityTableBracketsSecondary(false);        
                        }
                    }
                    else if(dcoTableFramePrimary.productID.indexOf("HRMALYS")>-1)
                    {

                        if(dcoTableFramePrimary.productID.indexOf("66Z28Z16Z")>-1)
                        {
                            mxtglTF.setVisibilityTableFramePrimary(true);
                            mxtglTF.setVisibilityTableFrameSecondary(false);
                            mxtglTF.setVisibilityTableShelfPrimary(true);
                            mxtglTF.setVisibilityTableDrawer(false);
                            mxtglTF.setVisibilityTableBracketsPrimary(true);
                            mxtglTF.setVisibilityTableBracketsSecondary(false);        
                        }
                        else
                        {
                            mxtglTF.setVisibilityTableFramePrimary(true);
                            mxtglTF.setVisibilityTableFrameSecondary(false);
                            mxtglTF.setVisibilityTableShelfPrimary(true);
                            mxtglTF.setVisibilityTableDrawer(false);
                            mxtglTF.setVisibilityTableBracketsPrimary(true);
                            mxtglTF.setVisibilityTableBracketsSecondary(false);        
                        }

                        
                    }
                    else if(dcoTableFramePrimary.productID.indexOf("HRMALNHS")>-1)
                    {
                        mxtglTF.setVisibilityTableFramePrimary(true);
                        
                        mxtglTF.setVisibilityTableShelfPrimary(dcoShelfCover!=null);
                        mxtglTF.setVisibilityTableDrawer(false);
                        mxtglTF.setVisibilityTableBracketsPrimary(dcoShelfCover!=null);
                        mxtglTF.setVisibilityTableBracketsSecondary(false);    

                        if(dcoTableFrameSecondary != null && dcoTableFrameSecondary.productID=="remove")
                        {
                            mxtglTF.setVisibilityTableFrameSecondary(false);
                            mxtglTF.setVisibilityTableShelfSecondary(false);
                        }
                        else
                        {
                            mxtglTF.setVisibilityTableFrameSecondary(true);
                            mxtglTF.setVisibilityTableShelfSecondary(true);
                            mxtglTF.setTableMaterialShelfSecondary(dcoTableTopCover.productID);
                        }
                    }
                }

                if (dcoTableFrameCover != null) {

                    mxtglTF.setTableMaterialFrame(dcoTableFrameCover.productID);
                }

                if (dcoShelfCover != null) {

                    mxtglTF.setTableMaterialShelfPrimary(dcoShelfCover.productID);                            
                    mxtglTF.setTableMaterialDrawer(dcoShelfCover.productID);
                }

                resolve();
            }, (theError) => {

                reject(theError);
            });
        });
    }

    renderglTF(theProductID, theDefaultChildOverrides) {

        for(let i = 0; i < this.mxtglTFArray.length; ++i) {
            this.mxtglTFArray[i].setBackgroundTable();

            if (i == 1) {
                this.mxtglTFArray[i].setBackgroundFullscreen();
            }
        }

        const theDCOs = JSON.parse(theDefaultChildOverrides);
        const dcoTableFramePrimary = this._findDCOByStepType('Table Frame Primary', theDCOs);
        if (dcoTableFramePrimary == null) {

            // console.log('Unable to find "Table Frame Primary" dco.');
        } else {

            if (dcoTableFramePrimary.productID in this.productIDsToglTFs) {

                this._showLoadingElements();
                
                let urlTable = this.productIDsToglTFs[dcoTableFramePrimary.productID];

                //
                // Handle the pivot point case.
                const dcoTableFrameSecondary = this._findDCOByStepType('Table Frame Secondary', theDCOs);
                if (dcoTableFramePrimary.productID == 'HRMALNHS-66Z28Z16Z-FRAME' &&
                    (dcoTableFrameSecondary == null ||
                     dcoTableFrameSecondary.productID != "remove"))
                {
                    urlTable = this.productIDsToglTFs[dcoTableFramePrimary.productID + '-CENTERED'];
                }


                const promises = [];
                for(let i = 0; i < this.mxtglTFArray.length; ++i) {
                    promises.push(this._loadTable(this.mxtglTFArray[i], urlTable, theDCOs, dcoTableFramePrimary, dcoTableFrameSecondary));
                }
                Promise.all(promises).then(()=> {
                    this._hideLoadingElements();
                }, theError=> {
                    console.log("Error: " + theError);
                    for(let i = 0; i < this.mxtglTFArray.length; ++i) {
                        this.mxtglTFArray[i].clearScene();
                    }
                    this._hideLoadingElements();
                });
                
            } else {

                console.log('Unable to find table glTF for ' + dcoTableFramePrimary.productID);
                for(let i = 0; i < this.mxtglTFArray.length; ++i) {
                    this.mxtglTFArray[i].clearScene();
                }
            }
        }
    }

    //
    // Helpers

    _findDCOByStepType(theStepType, theDCOs) {

        let result = null;

        theDCOs.forEach(function(theDCO) {
            if (theDCO.stepType == theStepType) {
                result = theDCO;
            }
        });

        return result;
    }

    _showLoadingElements() {

        const theLoader = document.getElementById('imageSpinsLoader');
        if (theLoader != null) {
            theLoader.style.visibility = "visible";
        }

        const imageSpinsLoaderElement = document.getElementById("imageSpinsLoader");
        if (imageSpinsLoaderElement != null) {
            imageSpinsLoaderElement.src = LoadingIcon;
            imageSpinsLoaderElement.style.visibility = "visible";
        }
        
        const imageSpinsPlaceholderElement = document.getElementById("imageSpinsPlaceholder");
        if (imageSpinsPlaceholderElement != null) {
            imageSpinsPlaceholderElement.src = null;
            imageSpinsPlaceholderElement.style.visibility = "hidden";
        }
        
        const imageSpins = document.getElementById("imageSpins");
        if (imageSpins != null) {
            imageSpins.style.visibility = "hidden";
        }
        
        const imageSpinsReel = document.getElementById("imageSpins-reel");
        if (imageSpinsReel != null) {
            imageSpinsReel.style.visibility = "hidden";
        }
        
        const fullScreenButton = document.getElementById('spins-button-fullscreen');
        if (fullScreenButton != null) {
            fullScreenButton.style.opacity = 0.5;
            fullScreenButton.disabled = true;
        }
    
        const rightButton = document.getElementById('spins-button-right');
        if (rightButton != null) {
            rightButton.style.opacity = 0.5;
            rightButton.disabled = true;
        }
    
        const leftButton = document.getElementById('spins-button-left');
        if (leftButton != null) {
            leftButton.style.opacity = 0.5;
            leftButton.disabled = true;
        }
    
        const extraButtons = document.getElementById('spinExtraButtonsContainer');
        if (extraButtons != null) {
            extraButtons.style.opacity = 0.5;
            
            const downloadButton = document.getElementById('spinsDownloadButton');
            if (downloadButton != null) {
                downloadButton.disabled = true;
            }
    
            const copyShareLinkTrigger = document.getElementById('copyShareLinkTrigger');
            if (copyShareLinkTrigger != null) {
                copyShareLinkTrigger.disabled = true;
            }
        }
    }
    
    _hideLoadingElements() {
    
        const theLoader = document.getElementById('imageSpinsLoader');
        if (theLoader != null) {
            theLoader.style.visibility = "hidden";
        }
        
        const imageSpinsLoaderElement = document.getElementById("imageSpinsLoader");
        if (imageSpinsLoaderElement != null) {
            imageSpinsLoaderElement.src = LoadingIcon;
            imageSpinsLoaderElement.style.visibility = "hidden";
        }
        
        const imageSpinsPlaceholderElement = document.getElementById("imageSpinsPlaceholder");
        if (imageSpinsPlaceholderElement != null) {
            imageSpinsPlaceholderElement.src = null;
            imageSpinsPlaceholderElement.style.visibility = "hidden";
        }
    
        const imageSpins = document.getElementById("imageSpins");
        if (imageSpins != null) {
            imageSpins.style.visibility = "visible";
        }
        
        const imageSpinsReel = document.getElementById("imageSpins-reel");
        if (imageSpinsReel != null) {
            imageSpinsReel.style.visibility = "visible";
        }
        
        const fullScreenButton = document.getElementById('spins-button-fullscreen');
        if (fullScreenButton != null) {
            fullScreenButton.style.opacity = 1.0;
            fullScreenButton.disabled = false;
        }
    
        const rightButton = document.getElementById('spins-button-right');
        if (rightButton != null) {
            rightButton.style.opacity = 1.0;
            rightButton.disabled = false;
        }
    
        const leftButton = document.getElementById('spins-button-left');
        if (leftButton != null) {
            leftButton.style.opacity = 1.0;
            leftButton.disabled = false;
        }
    
        const extraButtons = document.getElementById('spinExtraButtonsContainer');
        if (extraButtons != null) {
            extraButtons.style.opacity = 1.0;
    
            const downloadButton = document.getElementById('spinsDownloadButton');
            if (downloadButton != null) {
                downloadButton.disabled = false;
            }
    
            const copyShareLinkTrigger = document.getElementById('copyShareLinkTrigger');
            if (copyShareLinkTrigger != null) {
                copyShareLinkTrigger.disabled = false;
            }
        }
    }
}
