
import * as THREE from 'three';
import GLTFLoader from 'three-gltf-loader';
import OrbitControlCreator from './OrbitControls';

import SKYBOX_NEG_X from "../skybox/LoftEnv/negx.jpg";
import SKYBOX_NEG_Y from "../skybox/LoftEnv/negy.jpg";
import SKYBOX_NEG_Z from "../skybox/LoftEnv/negz.jpg";
import SKYBOX_POS_X from "../skybox/LoftEnv/posx.jpg";
import SKYBOX_POS_Y from "../skybox/LoftEnv/posy.jpg";
import SKYBOX_POS_Z from "../skybox/LoftEnv/posz.jpg";
import TABLE_GRADIENT from "../gradients/tableGradient.jpg";
import CHAIR_GRADIENT from "../gradients/chairGradient.jpg";
import WHITE_GRADIENT from "../gradients/whiteGradient.jpg";

export class MxtglTF {

    /**
     * 
     * @param {HTMLElement} divParent Also uses this element to resize the canvas
     */
    constructor(divParent, onResize) {

        const FOV = 60.0;
        const NEAR = 0.01;
        const FAR = 100;

        this.ASPECT = 0.81;

        //
        // Store the parent.

        this.divParent = divParent;
        this.onResize = onResize;

        //
        // Load up Three JS.

        this.camera = new THREE.PerspectiveCamera(FOV, this.ASPECT, NEAR, FAR);

        const OrbitControls = OrbitControlCreator(THREE);
        this.controls = new OrbitControls(this.camera, this.divParent);
        this.controls.enablePan = false;
        this.controls.enableKeys = false;
        this.controls.update();

        //
        // Initialize the scene
        this.scene = new THREE.Scene();

        this.scene.background = new THREE.Color( 0xffffff );

        //
        // Load in the six sides of our cubemap for reflections 
        this.envMap = new THREE.CubeTextureLoader().load([
            SKYBOX_POS_X, SKYBOX_NEG_X,
            SKYBOX_POS_Y, SKYBOX_NEG_Y,
            SKYBOX_POS_Z, SKYBOX_NEG_Z
        ]);

        //
        // create our hemisphere light for global environment lighting
        //this.light = new THREE.HemisphereLight(0xbfbfbf, 0xababab);
        this.light = new THREE.AmbientLight(0xffffff, 2);
        //this.light.position.set(0, 1, 0);
        this.scene.add(this.light);

        //
        // create our scene spot light for our main lighting source
        const spotLight = new THREE.SpotLight(0xffffff, 0.5, 10, 1.39626, 50, 2);
        spotLight.position.set(0,6,0);
        spotLight.castShadow = false;
        spotLight.shadow.mapSize.width = 512;
        spotLight.shadow.mapSize.height = 512;
        spotLight.shadow.camera.near = 500;
        spotLight.shadow.camera.far = 4000;
        spotLight.shadow.camera.fov = 30;
        this.scene.add(spotLight);

        this.renderer = new THREE.WebGLRenderer({ antialias: true });

        // console.log("size:" + this.divParent.offsetWidth + ":" + (this.divParent.offsetWidth/this.ASPECT) );
        this.renderer.setSize(this.divParent.offsetWidth, this.divParent.offsetWidth/this.ASPECT);
        this.renderer.gammaOutput = false;
        this.canvas = this.renderer.domElement;
        this.canvas.id = 'gltfCanvas';
        this.canvas.style.width = this.divParent.offsetWidth;
        this.canvas.style.height = this.divParent.offsetWidth/this.ASPECT;

        // console.log("new size:" + this.canvas.style.width + "," + this.canvas.style.height);

        this.divParent.appendChild(this.canvas);

        //
        // Chair / Stool Specific Variables

        this.armRest4D = [];
        this.armRestFixed = [];
        this.armRestHeightAdjustable = [];
        this.chairBase = [];
        this.chairCarrier = [];
        this.chairCaster = [];
        this.chairFrame = [];
        this.chairHeightExtenderStandard = [];
        this.chairHeightExtenderStool = [];
        this.chairKnitBack = [];
        this.chairLumbar = [];
        this.chairSeat = [];
        this.chairStitches = [];

        this.armRest4DMaterialNode = null;
        this.armRestFixedMaterialNode = null;
        this.armRestHeightAdjustableMaterialNode = null;
        this.chairBaseMaterialNode = null;
        this.chairCarrierMaterialNode = null;
        this.chairCasterMaterialNode = null;
        this.chairFrameMaterialNode = null;
        this.chairHeightExtenderStandardMaterialNode = null;
        this.chairHeightExtenderStoolMaterialNode = null;
        this.chairKnitBackMaterialNode = null;
        this.chairLumbarMaterialNode = null;
        this.chairSeatMaterialNode = null;
        this.chairStitchesMaterialNode = null;



        this.materials = null;
        



        // this.meshChairStoolChassis = null;
        // this.meshesChairStoolBack = { };
        // this.meshesChairStoolArms = { };
        // this.meshesChairStoolBases = { };
        // this.meshesChairStoolCasters = { };
        // this.meshesChairStoolHeightAdjustment = { };

        // this.materialsChairStoolFrame = { };
        // this.materialsSuspension = { };

        // this.urlChairStool = null;
        // this.urlMaterialsChairStoolFrame = null;
        // this.urlMaterialsSuspension = null;

        //
        // Table Specific Variables

        // this.meshTableFramePrimary = null;
        // this.meshTableFrameSecondary = null;
        // this.meshBracketsPrimary = null;
        // this.meshBracketsSecondary = null;
        // this.meshTableShelfPrimary = null;
        // this.meshTableShelfSecondary = null;
        // this.meshTableDrawer = null;

        // this.materialsTableFrame = { };
        // this.materialsShelfDrawer = { };

        // this.urlTable = null;
        // this.urlMaterialsTableFrame = null;
        // this.urlMaterialsShelfDrawer = null;

        this.onResize(this.renderer, this.camera);
        this.animate();

        const that = this;
        window.addEventListener("resize", ()=> {
            that.onResize(that.renderer, that.camera);
        }, false);
    }

    onWindowResize() {
        this.onResize(this.renderer, this.camera);
    }

    getScreenshotUrl() {
        this.renderer.render(this.scene, this.camera);
        return this.renderer.domElement.toDataURL();
    }

    animate() {
        requestAnimationFrame(this.animate.bind(this));

        this.controls.update();
        this.renderer.render(this.scene, this.camera);
    }

    async loadFrame()
    {
        const chairFrame = await this._loadglTF('assets/gltfs/Frame/Frame.gltf');
        this.scene.add(chairFrame.scene);

        chairFrame.scene.traverse((child)=> {

            if (child instanceof THREE.Mesh) {

                // console.log("child:" + child.material.name);
                if(child.material.name=="Frame_0")
                {
                    this.chairFrameMaterialNode = child;
                }

                this.chairFrame.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadSeat()
    {

        const chairSeat = await this._loadglTF('assets/gltfs/Seat/Seat.gltf');
        this.scene.add(chairSeat.scene);

        chairSeat.scene.traverse( (child)=> {

            if (child instanceof THREE.Mesh) {

                // console.log("child:" + child.material.name);
                if(child.material.name=="Seat_0")
                {
                    this.chairSeatMaterialNode = child;
                }

                this.chairSeat.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadArm4D()
    {

        const armRest4D = await this._loadglTF('assets/gltfs/ArmRest_4DAdjustable/ArmRest_4DAdjustable.gltf');
        this.scene.add(armRest4D.scene);

        armRest4D.scene.traverse( (child)=> {

            if (child instanceof THREE.Mesh) {

                // console.log("4d:" + child.material.name);
                if(child.material.name=="ArmRest_Adjustable_0")
                {
                    this.armRest4DMaterialNode = child;
                }

                this.armRest4D.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });

    }

    async loadArmFixed()
    {
        const armRestFixed = await this._loadglTF('assets/gltfs/ArmRest_Fixed/ArmRest_Fixed.gltf');
        this.scene.add(armRestFixed.scene);

        armRestFixed.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="ArmRest_Fixed_0")
                {
                    this.armRestFixedMaterialNode = child;
                }
                this.armRestFixed.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadArmHeightAdjust()
    {

        const armRestHeightAdjustable = await this._loadglTF('assets/gltfs/Armrest_HeightAdjustable/ArmRest_HeightAdjustable.gltf');
        this.scene.add(armRestHeightAdjustable.scene);

        armRestHeightAdjustable.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log("ha:" + child.material.name);
                if(child.material.name=="ArmRest_Adjustable_0")
                {
                    this.armRestHeightAdjustableMaterialNode = child;
                }

                this.armRestHeightAdjustable.push(child);
                child.visible = false;


                child.material.envMap = this.envMap;
                                
            }
        });
    }

    async loadBase()
    {

        const chairBase = await this._loadglTF('assets/gltfs/Base/Base.gltf');
        this.scene.add(chairBase.scene);

        chairBase.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="Base_0")
                {
                    this.chairBaseMaterialNode = child;
                }

                this.chairBase.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadCarrier()
    {

        const chairCarrier = await this._loadglTF('assets/gltfs/Carrier/Carrier.gltf');
        this.scene.add(chairCarrier.scene);

        chairCarrier.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="Carrier_0")
                {
                    this.chairCarrierMaterialNode = child;
                }

                this.chairCarrier.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadCaster()
    {

        const chairCaster = await this._loadglTF('assets/gltfs/Caster/Caster.gltf');
        this.scene.add(chairCaster.scene);

        chairCaster.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="Caster_1")
                {
                    this.chairCasterMaterialNode = child;
                }

                this.chairCaster.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadHeightExtenderStandard()
    {

        const chairHeightExtenderStandard = await this._loadglTF('assets/gltfs/HeightExtender_Standard/HeightExtender_Standard.gltf');
        this.scene.add(chairHeightExtenderStandard.scene);

        chairHeightExtenderStandard.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="HeightExtender_Standard_0")
                {
                    this.chairHeightExtenderStandardMaterialNode = child;
                }

                this.chairHeightExtenderStandard.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadHeightExtenderStool()
    {

        const chairHeightExtenderStool = await this._loadglTF('assets/gltfs/HeightExtender_Stool/HeightExtender_Stool.gltf');
        this.scene.add(chairHeightExtenderStool.scene);

        chairHeightExtenderStool.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="HeightExtender_Stool_0")
                {
                    this.chairHeightExtenderStoolMaterialNode = child;
                }

                this.chairHeightExtenderStool.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadKnitBack()
    {

        const chairKnitBack = await this._loadglTF('assets/gltfs/KnitBack/KnitBack.gltf');
        this.scene.add(chairKnitBack.scene);

        chairKnitBack.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="KnitBack_0_Loft")
                {
                    this.chairKnitBackMaterialNode = child;
                }

                this.chairKnitBack.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadLumbar()
    {

        const chairLumbar = await this._loadglTF('assets/gltfs/Lumbar/Lumbar.gltf');
        this.scene.add(chairLumbar.scene);

        chairLumbar.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="Lumbar_0")
                {
                    this.chairLumbarMaterialNode = child;
                }

                this.chairLumbar.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadStitches()
    {
            

        const chairStitches = await this._loadglTF('assets/gltfs/Stitches/Stitches.gltf');
        this.scene.add(chairStitches.scene);

        chairStitches.scene.traverse((child) => {

            if (child instanceof THREE.Mesh) {

                // console.log(child.material.name);
                if(child.material.name=="Seat_0")
                {
                    this.chairStitchesMaterialNode = child;
                }

                this.chairStitches.push(child);
                child.visible = false;

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadMaterials()
    {

        const Materials = await this._loadglTF('assets/materials/Material_Atlas.gltf');
        this.scene.add(Materials.scene);

        this.materials = new Map();
        
        Materials.scene.traverse((child) => {
            if(child instanceof THREE.Mesh)
            {
                child.visible = false;
                // console.log("mesh:" + child.material.name);
                this.materials.set(child.material.name,child.material);

                child.material.envMap = this.envMap;
            }
        });
    }

    async loadChairStool(urlChairStool, urlMaterialsChairStoolFrame, urlMaterialsSuspension, onSuccess, onError) {

        try {


            if(this.chairFrame.length>0)
            {
                for(let child of this.chairFrame)
                {
                    child.visible = false;
                }

                for(let child of this.armRest4D)
                {
                    child.visible = false;
                }

                for(let child of this.armRestFixed)
                {
                    child.visible = false;
                }

                for(let child of this.armRestHeightAdjustable)
                {
                    child.visible = false;
                }
                
                for(let child of this.chairBase)
                {
                    child.visible = false;
                }
                
                for(let child of this.chairCarrier)
                {
                    child.visible = false;
                }
                
                for(let child of this.chairCaster)
                {
                    child.visible = false;
                }
                
                
                for(let child of this.chairHeightExtenderStandard)
                {
                    child.visible = false;
                }
                
                for(let child of this.chairHeightExtenderStool)
                {
                    child.visible = false;
                }
                
                
                for(let child of this.chairKnitBack)
                {
                    child.visible = false;
                }
                
                
                for(let child of this.chairLumbar)
                {
                    child.visible = false;
                }
                
                for(let child of this.chairSeat)
                {
                    child.visible = false;
                }
                
                for(let child of this.chairStitches)
                {
                    child.visible = false;
                }
                
                if (onSuccess != null) {
                    onSuccess();
                }
                return;
            }

            //
            // Clear out the previous scene.

            this.clearScene();

            const promises = [];

            promises.push(this.loadFrame());
            promises.push(this.loadSeat());
            promises.push(this.loadArm4D());
            promises.push(this.loadArmFixed());
            promises.push(this.loadArmHeightAdjust());
            promises.push(this.loadBase());
            promises.push(this.loadCarrier());
            promises.push(this.loadCaster());
            promises.push(this.loadHeightExtenderStandard());
            promises.push(this.loadHeightExtenderStool());
            promises.push(this.loadKnitBack());
            promises.push(this.loadLumbar());
            promises.push(this.loadStitches());
            promises.push(this.loadMaterials());
            
            await Promise.all(promises);



            if (onSuccess != null) {
                onSuccess();
            }
        } catch (theError) {
            console.error(theError);
            if (onError != null) {
                onError(theError);
            }
        }

        return;
        if (this.urlChairStool == urlChairStool && this.urlMaterialsChairStoolFrame == urlMaterialsChairStoolFrame && this.urlMaterialsSuspension == urlMaterialsSuspension) {
            if (onSuccess != null) {
                onSuccess();
            }
            return;
        }

        //
        // Store the URLs.

        this.urlChairStool = urlChairStool;
        this.urlMaterialsChairStoolFrame = urlMaterialsChairStoolFrame;
        this.urlMaterialsSuspension = urlMaterialsSuspension;

        this.urlTable = null;
        this.urlMaterialsTableFrame = null;
        this.urlMaterialsShelfDrawer = null;

        try {

            const that = this;

            //
            // Clear out the previous scene.

            that.clearScene();

            //
            // Load the glTFs.
            const gltfChairStool                = await that._loadglTF(urlChairStool);
            const gltfMaterialsChairStoolFrame  = await that._loadglTF(urlMaterialsChairStoolFrame);
            const gltfMaterialsSuspension       = await that._loadglTF(urlMaterialsSuspension);

            if (this.urlChairStool != urlChairStool && this.urlMaterialsChairStoolFrame != urlMaterialsChairStoolFrame && this.urlMaterialsSuspension != urlMaterialsSuspension) {
                if (onError != null) {
                    onError('Chair / Stool is no longer the current chair / stool.');
                }
                return;
            }

            if (gltfChairStool != null) {

                //
                // Retrieve the chair / stool meshes.

                this.meshChairStoolChassis = gltfChairStool.scene.getObjectByName("HRMCOSM-CHASSIS", true);

                const armsContainer = gltfChairStool.scene.getObjectByName("ARMS", true);
                armsContainer.children.forEach(function(child) {
                    if (child instanceof THREE.Mesh) {
                        that.meshesChairStoolArms[child.name] = child;
                    }
                });

                const backsContainer = gltfChairStool.scene.getObjectByName("BACKS", true);
                backsContainer.children.forEach(function(child) {
                    if (child instanceof THREE.Mesh) {
                        that.meshesChairStoolBack[child.name] = child;
                    }
                });

                const basesContainer = gltfChairStool.scene.getObjectByName("BASES", true);
                basesContainer.children.forEach(function(child) {
                    if (child instanceof THREE.Mesh || child instanceof THREE.Group) {
                        that.meshesChairStoolBases[child.name] = child;
                    }
                });

                const heightextendersContainer = gltfChairStool.scene.getObjectByName("HEIGHTEXTENDERS", true);
                heightextendersContainer.children.forEach(function(child) {
                    if (child instanceof THREE.Group) {
                        that.meshesChairStoolHeightAdjustment[child.name] = child;
                    }
                });

                const castersContainer = gltfChairStool.scene.getObjectByName("CASTERS", true);
                castersContainer.children.forEach(function(child) {
                    if (child instanceof THREE.Object3D) {
                        that.meshesChairStoolCasters[child.name] = child;
                    }
                });

                //
                // Apply the environment map.

                gltfChairStool.scene.traverse(function(node) {
                    if (node instanceof THREE.Mesh) {
                        node.material.envMap = that.envMap;
                    }
                });

                //
                // Configure the initial state.

                that.setVisibilityChairStoolArm(null);
                that.setVisibilityChairStoolBack(null);
                that.setVisibilityChairStoolBase(null);
                that.setVisibilityChairStoolHeightAdjustment(null);
                that.setVisibilityChairStoolCasters(null);

                that.scene.add(gltfChairStool.scene);
            }

            if (gltfMaterialsChairStoolFrame != null) {

                gltfMaterialsChairStoolFrame.scene.traverse(function (child) {

                    if (child instanceof THREE.Scene) {

                        child.children.forEach(function(childInner) {
                            
                            if (childInner instanceof THREE.Mesh) {

                                childInner.material.forEach(function(material) {
                                    
                                    //
                                    // Apply the skybox to our frame materials environment map slot
                                    material.envMap = that.envMap;
                                    that.materialsChairStoolFrame[material.name] = material;
                                });
                            }
                        });
                    }
                });
            }

            if (gltfMaterialsSuspension != null) {

                gltfMaterialsSuspension.scene.traverse(function (child) {

                    if (child instanceof THREE.Scene) {

                        child.children.forEach(function(childInner) {
                            
                            if (childInner instanceof THREE.Mesh) {

                                childInner.material.forEach(function(material) {
                                    
                                    //
                                    // Apply the skybox to our frame materials environment map slot
                                    material.envMap = that.envMap;
                                    that.materialsSuspension[material.name] = material;
                                });
                            }
                        });
                    }
                });
            }

            if (onSuccess != null) {
                onSuccess();
            }
        } catch (theError) {
            console.error(theError);
            if (onError != null) {
                onError(theError);
            }
        }
    }

    //
    // Chair / Stool Visibility Section

   

    setVisibilityChairStoolArm(productID) {

        if (this.meshesChairStoolArms != null) {
            for (const key in this.meshesChairStoolArms) {
                const mesh = this.meshesChairStoolArms[key];
                mesh.visible = false;
            }

            if (productID in this.meshesChairStoolArms) {
                this.meshesChairStoolArms[productID].visible = true;
            }
        }
    }

    setVisibilityChairStoolBack(productID) {

        if (this.meshesChairStoolBack != null) {
            for (const key in this.meshesChairStoolBack) {
                const mesh = this.meshesChairStoolBack[key];
                mesh.visible = false;
            }

            if (productID in this.meshesChairStoolBack) {
                this.meshesChairStoolBack[productID].visible = true;
            }
        }
    }

    setVisibilityChairStoolBase(productID) {

        if (this.meshesChairStoolBases != null) {
            for (const key in this.meshesChairStoolBases) {
                const mesh = this.meshesChairStoolBases[key];
                mesh.visible = false;
            }

            if (productID in this.meshesChairStoolBases) {
                this.meshesChairStoolBases[productID].visible = true;
            }
        }
    }

    setVisibilityChairStoolHeightAdjustment(productID) {

        if (this.meshesChairStoolHeightAdjustment != null) {
            for (const key in this.meshesChairStoolHeightAdjustment) {
                const mesh = this.meshesChairStoolHeightAdjustment[key];
                mesh.visible = false;
            }

            if (productID in this.meshesChairStoolHeightAdjustment) {
                this.meshesChairStoolHeightAdjustment[productID].visible = true;
            }
        }
    }

    setVisibilityChairStoolCasters(productID) {

        if (this.meshesChairStoolCasters != null) {
            for (const key in this.meshesChairStoolCasters) {
                const mesh = this.meshesChairStoolCasters[key];
                mesh.visible = false;
            }

            if (productID in this.meshesChairStoolCasters) {
                this.meshesChairStoolCasters[productID].visible = true;
            }
        }
    }

    //
    // Chair Material Section

    setChairStoolMaterialArms(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshesChairStoolArms != null) {
            for (const key in this.meshesChairStoolArms) {
                const mesh = this.meshesChairStoolArms[key];
                mesh.material = theMaterial;
            }
        }
    }

    setChairStoolMaterialArmPads(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshesChairStoolArms != null) {
            for (const key in this.meshesChairStoolArms) {
                const mesh = this.meshesChairStoolArms[key];
                mesh.traverse(function(child) {
                    if (child.name.includes('REST')) {
                        child.material = theMaterial;
                    }
                });
            }
        }
    }

    setChairStoolMaterialBack(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshesChairStoolBack != null) {
            for (const key in this.meshesChairStoolBack) {
                const mesh = this.meshesChairStoolBack[key];
                mesh.material = theMaterial;
            }
        }
    }

    setChairStoolMaterialTrims(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshesChairStoolBack != null) {
            for (const key in this.meshesChairStoolBack) {
                const mesh = this.meshesChairStoolBack[key];
                mesh.traverse(function(child) {
                    if (child.name.includes('TRIM')) {
                        child.material = theMaterial;
                    }
                });
            }
        }

        if (theMaterial != null && this.meshesChairStoolArms != null) {
            for (const key in this.meshesChairStoolArms) {
                const mesh = this.meshesChairStoolArms[key];
                mesh.traverse(function(child) {
                    if (child.name.includes('TRIM')) {
                        child.material = theMaterial;
                    }
                });
            }
        }
    }

    setChairStoolMaterialChassis(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshChairStoolChassis != null) {
            if (this.meshChairStoolChassis.children != null && this.meshChairStoolChassis.children.length > 1) {
                this.meshChairStoolChassis.children[1].material = theMaterial;
            } else {
                this.meshChairStoolChassis.material = theMaterial;
            }
        }
    }

    setChairStoolMaterialBase(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshesChairStoolBases != null) {
            for (const key in this.meshesChairStoolBases) {
                const mesh = this.meshesChairStoolBases[key];

                if (key == 'HRMCOSM-BASE-SEMI-POLISHED') {
                    if (mesh.children != null && mesh.children.length > 1) {
                        mesh.children[0].material = theMaterial;
                    } else {
                        mesh.material = theMaterial;
                    }
                } else {
                    mesh.material = theMaterial;
                }
            }
        }
    }

    setChairStoolMaterialCasters(productID) {

        let theMaterial = null;

        if (this.materialsChairStoolFrame != null && productID in this.materialsChairStoolFrame) {
            theMaterial = this.materialsChairStoolFrame[productID];
        }

        if (theMaterial != null && this.meshesChairStoolCasters != null) {
            for (const key in this.meshesChairStoolCasters) {
                const mesh = this.meshesChairStoolCasters[key];
                mesh.traverse(function(child) {
                    if (child.name.includes('FRAME')) {
                        child.material = theMaterial;
                    }
                });
            }
        }
    }

    setChairStoolMaterialSuspension(productID) {

        let theMaterial = null;

        if (this.materialsSuspension != null && productID in this.materialsSuspension) {
            theMaterial = this.materialsSuspension[productID];
        }

        if (theMaterial != null && this.meshesChairStoolBack != null) {
            for (const key in this.meshesChairStoolBack) {
                const mesh = this.meshesChairStoolBack[key];
                mesh.traverse(function(child) {
                    if (child.name.includes('MESH')) {
                        child.material = theMaterial;
                    }
                });
            }
        }

        if (theMaterial != null && this.meshesChairStoolArms != null) {
            for (const key in this.meshesChairStoolArms) {
                const mesh = this.meshesChairStoolArms[key];
                mesh.traverse(function(child) {
                    if (child.name.includes('MESH')) {
                        child.material = theMaterial;
                    }
                });
            }
        }
    }

    zoomIn() {
        this.controls.dollyIn(1.25);
        this.controls.update();
    }
    zoomOut() {
        this.controls.dollyOut(1.25);
        this.controls.update();
    }

    //
    // Scene Management Section

    incrementCameraRotationY(deltaRadians) {
        this.controls.rotateLeft(-deltaRadians);
        this.controls.update();
    }

    setCameraPosition(x, y, z) {
        // console.log("set camera position:" + x + "," + y + "," + z);
        this.camera.position.x = x;
        this.camera.position.y = y;
        this.camera.position.z = z;
        this.controls.update();
    }

    getCameraPosition() {
        return [ this.camera.position.x, this.camera.position.y, this.camera.position.z ];
    }

    setCameraTarget(x, y, z) {
        this.controls.target.set(x, y, z);
        this.controls.update();
    }

    getCameraTarget() {
        return [ this.controls.target.x, this.controls.target.y, this.controls.target.z ];
    }

    setCameraFOV(fov) {
        this.camera.fov = fov;
        this.camera.updateProjectionMatrix();
        this.controls.update();
    }

    setCameraZoomMinMax(min, max) {
        this.controls.minDistance = min;
        this.controls.maxDistance = max;
        this.controls.update();
    }

    enableCameraVerticalRotation() {
        this.controls.minPolarAngle = 0.0;
        this.controls.maxPolarAngle = Math.PI;
        this.controls.update();
    }

    disableCameraVerticalRotation() {
        this.controls.minPolarAngle = this.controls.getPolarAngle();
        this.controls.maxPolarAngle = this.controls.getPolarAngle();
        this.controls.update();
    }

    setBackgroundChair() {

        if(this.backgroundChair)
        {
            this.scene.background = this.backgroundChair;
        }
        else
        {        
            const that = this;
            // this.backgroundChair = new THREE.TextureLoader().load(CHAIR_GRADIENT,function(event){

            //     // The actual texture is returned in the event.content
            //     that.scene.background = that.backgroundChair;        
            // });
        }
        
    }

    setBackgroundTable() {
        if(this.backgroundTable)
        {
            this.scene.background = this.backgroundTable;
        }
        else
        {
            const that = this;
            // Load in our initial background gradient texture and set our scene to use it
            // this.backgroundTable = new THREE.TextureLoader().load(TABLE_GRADIENT,function(event){

            //     // The actual texture is returned in the event.content
            //     that.scene.background = that.backgroundTable;
            
            // });
        }
    }

    setBackgroundFullscreen() {
        if(this.backgroundFullscreen)
        {
            this.scene.background = this.backgroundFullscreen;
        }
        else
        {
            const that = this;
            // Load in our initial background gradient texture and set our scene to use it
            // this.backgroundFullscreen = new THREE.TextureLoader().load(WHITE_GRADIENT,function(event){

            //     // The actual texture is returned in the event.content
            //     that.scene.background = that.backgroundFullscreen;

            // });
        }
    }

    clearScene() {

        if (this.scene != null) {

            let toRemove = [];

            this.armRest4D = [];
            this.armRestFixed = [];
            this.armRestHeightAdjustable = [];
            this.chairBase = [];
            this.chairCarrier = [];
            this.chairCaster = [];
            this.chairFrame = [];
            this.chairHeightExtenderStandard = [];
            this.chairHeightExtenderStool = [];
            this.chairKnitBack = [];
            this.chairLumbar = [];
            this.chairSeat = [];
            this.chairStitches = [];

            this.scene.traverse(function(child) {
                if (child instanceof THREE.Scene) {
                    toRemove.push(child);
                }
            });

            for (let i = 0; i < toRemove.length; ++i) {
                this.scene.remove(toRemove[i]);
            }

        }
    }

    //
    // Helpers

    _loadglTF(url) {
        return new Promise((resolve, reject)=> {
            const loader = new GLTFLoader();
            loader.load(url, function(gltf) {
                resolve(gltf);
            }, undefined, function (e) {
                reject(e);
            });
        });
    }
}
