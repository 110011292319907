import HelpIcon from './../assets/images/icons/help.svg';
import GeigerHelpIcon from './../assets/images/icons/geiger-help.svg';
import chairConfig from './../assets/chair.json';
import ArrowRightIcon from '../assets/images/icons/arrow-right.svg';
import cartIcon from '../css/img/cart.svg';

import jquery from 'jquery';
import 'bootstrap';

export class BusinessRules {
    /**
     * @param {string} sku 
     */
    constructor(urlSpinServer, baseSku, enqueueProductToSpinsServer, passedInProductID,suspensionID, theConverter) {

        this.gltfRenderer = theConverter;

        // console.log("passed in product id:" + passedInProductID);
        this.chairJSON = chairConfig;

        this.apiBaseURL = urlSpinServer;
        this.configuratorDiv = document.getElementById('mxtConfigurator');

        this.baseSku = baseSku; // This is passed in from init and never should change
        this.baseSkuWithDefaults = null;
        this.baseSkuWithDefaultsExtended = null;

        // this.onSKUChangedCallback = onSKUChangedCallback;
        this.enqueueProductToSpinsServer = enqueueProductToSpinsServer;

        this.priceChangeAnimation = null;

        this.backHeight = null;
        this.heightAdjustment = null;
        this.tilt = null;
        this.seatDepth = null;
        this.arms = null;
        this.castersAndGlides = 'O2B';
        this.suspensionMaterial = '01';
        this.frameFinish = 'DB3';
        this.baseFinish = 'DB3';
        this.chassisFinish = 'DB3';
        this.armpadFinish = null;

        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        this.iPhone = !!navigator.platform && /iPhone|iPod/.test(navigator.platform);
        
        var ua = navigator.userAgent.toLowerCase();
        this.isAndroid = ua.indexOf("android") > -1;
        this.clickTouchEvent = iOS || this.isAndroid ? "touchend" : "click";
        this.mousedownTouchEvent = iOS || this.isAndroid ? "touchstart" : "mousedown";
        this.mouseupTouchEvent = iOS || this.isAndroid ? "touchend" : "mouseup";
        this.mouseoutTouchEvent = iOS || this.isAndroid ? "touchend" : "mouseout";


        this.createdStepsArray = [];

        // Price
        // this.isFirstPriceCall = true;
        this.currentPrice = null;

        this.fillerText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

        this.gradientColorTop = '#ebebebff';
        this.gradientColorBottom ='#fafafaff';
        this.fieldOfView = 20.0;
        this.cameraHeightOffset = -0.015;
        this.cameraHeightOffsetForAngle = 0.2;

        this.prevFieldOfView = this.fieldOfView;
        this.prevCameraHeightOffset =this.cameraHeightOffset;
        this.prevCameraHeightOffsetForAngle = this.cameraHeightOffsetForAngle;
        this.prevCameraDepthOffset = this.cameraDepthOffset;

        this.suspensionID = suspensionID;

        this.previousPrice = 0;
        this.newTotalPrice = 0;

        // document.getElementById('summaryButton').addEventListener(this.clickTouchEvent, () => {
        //     document.getElementById('summaryModal').setAttribute('style', 'display: block;');
        //     // document.body.setAttribute('style', 'overflow: hidden');
        //     this.populateSummaryModal();
        // });

        document.getElementById('editConfiguration').addEventListener(this.clickTouchEvent, () => {
            document.getElementById('summaryModal').setAttribute('style', 'display: none;');
            document.body.removeAttribute('style');
        });

        document.getElementById('summary-modal-close').addEventListener(this.clickTouchEvent, () => {
            document.getElementById('summaryModal').setAttribute('style', 'display: none;');
            document.body.removeAttribute('style');
        });

        document.getElementById('printSummary').addEventListener(this.clickTouchEvent, () => {
            this.printSummary();
        });

        let width = document.body.clientWidth;
        // console.log(width);
        if (width <= 767) {
            setTimeout(() => {
                $('.stepContainer').addClass('stepContainerHidden');
            }, 1000);
        }

        window.addEventListener('resize', function(e) {
            // console.log('resize');
            let width = document.body.clientWidth;
            if (width <= 767) {
                $('.stepContainer').addClass('stepContainerHidden');
            } else {
                $('.stepContainer').removeClass('stepContainerHidden');
            }
         });

        // Start things off
        // setTimeout(() => {
        // this.getConfigFile(this.baseSku);
        // }, 1000);
        // this.getProductData(this.baseSku);

        // console.log("baseSKU:" + baseSku);
        // if (baseSku == 'FLC1' || baseSku == 'FLC3') {
            this.productType = 'Chair';
            // this.updateProductType(this.productType);
        // } else if (baseSku == 'FLC7' || baseSku == 'FLC8') {
            //console.log(this.productType);
            // this.productType = 'Stool';
            // this.updateProductType(this.productType);
        // } else {
        //     this.productType = 'Table';
        // }

        this.selections = [];
        this.sku = [];
        this.sku2 = [];
        this.skuBreak = false;
        this.priceInfo = [];

        // console.log("passed:" + passedInProductID);

        this.getJSON(baseSku, passedInProductID);


        

        // Table gets a gradient override color
        if (this.productType == "Table") {

            this.gradientColorTop = '#f5f2ecff';
            this.gradientColorBottom = '#fbfaf7ff';
            // this.fieldOfView = 40.0;
            // this.cameraHeightOffset = -0.005;
            // this.cameraHeightOffsetForAngle = 0.35;

            $('head:first').append('<link rel="stylesheet" type="text/css" href="css/geiger.css" />');
            $('#product-not-available').hide();
            $('#printBrand').text('GEIGER');
            $('#printCopyright').text('© 2018 Geiger International');
        }
    }


    getJSON(baseSKU,passedInProductID) {
        // const config = localStorage.getItem('currentConfig');
        // console.log("getJSON:" + passedInProductID);
        
        var request = new XMLHttpRequest();
        request.onreadystatechange = () => {
            if (request.readyState == 4 && request.status == 200) {
                this.chairJSON = JSON.parse(request.responseText);

                this.initializeSelections(this.chairJSON, passedInProductID);
                this.start(this.chairJSON);
            }
        };  
        // request.open("GET", 'https://s3.amazonaws.com/vcassets/production/product_configurator/000030/000025/' + this.productType.toLowerCase() + '.json', true);

        request.open("GET", 'assets/chair.json', true);
        request.send();
    }



    initializeSelections(productJSON,productId)
    {
        // console.log("initialize selections:" + productId);
        if(!productId || productId.length==0)
        {
            return;
        }
        
        //Sort steps by sku order
        let sortedSteps = productJSON.steps.slice();
        sortedSteps.sort(function(step1,step2){            
            if(step1.sku_order==step2.sku_order)
                return 0;
            else if(step1.sku_order<step2.sku_order)
                return -1;
            else
                return 1;
        });

        let possibleSelections = [];

        // Find the possible steps and selections that match the sku
        let startIndex = 0;
        for(let i=0;i<sortedSteps.length;i++)
        {
            // console.log("sorted step:" + sortedSteps[i].step_id + ":" + sortedSteps[i].sku_order);
            const step = sortedSteps[i];            
            let startIndexLength = 0;
            if(step.options)
            {
                let sortedOptions = step.options.slice();
                sortedOptions.sort(function(option1,option2){            
                    if(option1.sku_component.length==option2.sku_component.length)
                        return 0;
                    else if(option1.sku_component.length<option2.sku_component.length)
                        return 1;
                    else
                        return -1;
                });

                for(let j=0;j<sortedOptions.length;j++)
                {
                    // console.log("check option:" + sortedOptions[j].option_id + ":" + sortedOptions[j].sku_component + ":" + productId.substring(startIndex));
                    if(sortedOptions[j].sku_component.length>0 && sortedOptions[j].sku_component.length>=startIndexLength  && productId.startsWith(sortedOptions[j].sku_component,startIndex))
                    {
                        // console.log("it's possible");
                        let possibleSelection = {};
                        possibleSelection.step_id = step.step_id;
                        possibleSelection.option_id = sortedOptions[j].option_id;
                        possibleSelections.push(possibleSelection);
                        startIndexLength = sortedOptions[j].sku_component.length;
                    }
                }
            }
            if(i<sortedSteps.length-1)
            {
                if(sortedSteps[i+1].sku_order>sortedSteps[i].sku_order || sortedSteps[i+1].sku_order==0)
                {
                    startIndex += startIndexLength;
                }
            }
        }

        // for(let i=0;i<possibleSelections.length;i++)
        // {
        //     console.log("possible selection:" + possibleSelections[i].step_id + "," + possibleSelections[i].option_id);
        // }

        //Sort the possible steps by step order to evaluate the rules
        possibleSelections.sort(function(step1,step2){            
            if(step1.step_id==step2.step_id)
            {
                if(step1.option_id==step2.option_id)
                    return 0;
                else if(step1.option_id<step2.option_id)
                    return -1;
                else   
                    return 1;
            }
            else 
            {   
                const step1Index = parseInt(step1.step_id.substring(1));
                const step2Index = parseInt(step2.step_id.substring(1));
                if(step1Index<step2Index)
                    return -1;
                else
                    return 1;
            }
        });

        let collapsedSelections = [];
        let collapsedSelection = {};        
        for(let i=0;i<possibleSelections.length;i++)
        {
            if(collapsedSelection.step_id && collapsedSelection.step_id==possibleSelections[i].step_id)
            {
                collapsedSelection.options.push(possibleSelections[i].option_id);
            }
            else
            {
                collapsedSelection = {};
                collapsedSelection.step_id = possibleSelections[i].step_id;
                collapsedSelection.options = [];
                collapsedSelection.options.push(possibleSelections[i].option_id);
                collapsedSelections.push(collapsedSelection);
            }
        }

        let parents = "";
        this.traverseRuleTree(collapsedSelections,productJSON.rules,parents,0);

        for(let i=0;i<collapsedSelections.length;i++)
        {

            // console.log("collapsedSelections:" + collapsedSelections[i].step_id + "," + collapsedSelections[i].options);
            let selection = {};
            selection.step_id = collapsedSelections[i].step_id;
            if(collapsedSelections[i].options.length==1)
            {
                selection.option_id=collapsedSelections[i].options[0];
                // console.log("pushing:" + selection.option_id);
                this.selections.push(selection);
            }
        }

    }

    traverseRuleTree(collapsedSelections,rule,parents,indent)
    {

        let tabs = "";
        for(let i=0;i<indent;i++)
        {
            tabs += "\t";
        }

        let selection;
        for(let i=0;i<collapsedSelections.length;i++)
        {
            if(collapsedSelections[i].step_id==rule.step_id)
            {
                selection = collapsedSelections[i];                
                break;
            }
        }

        if(selection)
        {
            // console.log(tabs + "step:" + rule.step_id + ":" + rule.visible_option_order + ":" + selection.options);

            let possible = false;
            for(let i=0;i<rule.visible_option_order.length;i++)
            {
                for(let s=0;s<selection.options.length;s++)
                {
                    if(rule.visible_option_order[i]==selection.options[s])
                    {
                        // console.log(tabs + "\tpossible option:" + selection.options[s]);
                        possible = true;
                        for(let opr=0;opr<rule.option_rules.length;opr++)
                        {                        
                            if(rule.option_rules[opr].option_id==selection.options[s])
                            {
                                for(let st=0;st<rule.option_rules[opr].steps.length;st++)
                                {
                                    this.traverseRuleTree(collapsedSelections,rule.option_rules[opr].steps[st],parents + "," + selection.step_id + ":" + selection.options[s],indent+2);                                    
                                }
                            }
                        }
                    }
                }            
            }        

            if(!possible)
            {
                // console.log(tabs + "not possible:" + parents);
                const parentsArray = parents.split(",");
                for(let p=0;p<parentsArray.length;p++)
                {
                    const parent = parentsArray[p].split(":");
                    const parent_step_id = parent[0];
                    const parent_option_id = parent[1];
                    for(let i=0;i<collapsedSelections.length;i++)
                    {
                        if(collapsedSelections[i].step_id==parent_step_id)
                        {
                            for(let o=0;o<collapsedSelections[i].options.length;o++)
                            {
                                if(collapsedSelections[i].options[o]==parent_option_id)
                                {
                                    collapsedSelections[i].options.splice(o,1);
                                    break;
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }
    }


    isBefore(elA, elB) {
        var el = $('*');
        if (elB.length == 0) {
            return false;
        }
        return (el.index($(elA).first()) < el.index($(elB).last()));
    }

    isAfter(elA, elB) {
        var el = $('*');
        if (elB.length == 0) {
            return false;
        }
        return (el.index($(elA).last()) > el.index($(elB).first()));
    }

    populateSummaryModal() {
        document.getElementById('summaryModalSkuValue').innerText = document.getElementById('sku').innerText;
        if ($('#sku2').text() != '') {
            document.getElementById('summaryModalSku2Value').innerText = $('#sku2').text();
            document.getElementById('summaryModalSku2').style.display = 'block';
        }
        // Non GLTF version
        // document.getElementById('summaryModalImage').setAttribute('src', document.getElementById('imageSpins').getAttribute('src'));
        document.getElementById('summaryModalImage').setAttribute('src', this.gltfRenderer.mxtglTF.getScreenshotUrl());
        document.getElementById('summaryModalPriceValue').innerText = document.getElementById('footer-price').innerText;

        document.getElementById('summaryModalDetailsList').innerHTML = '';
        var skuSelectedOptions = [];
        var sku2SelectedOptions = [];

        const that = this;
        $('#mxtConfigurator input:checked').each(function () {

            const isAfterSkuBreak = that.isAfter($(this).parent(), $('#skuBreak'));
            // console.log('isAfterSkuBreak?' + isAfterSkuBreak);
            if (isAfterSkuBreak == true) {
                sku2SelectedOptions.push('<p>' + $(this).parent().find('.customLabel').text() + '</p>');
            } else {
                skuSelectedOptions.push('<p>' + $(this).parent().find('.customLabel').text() + '</p>');
            }


        });

        document.getElementById('summaryModalDetailsList').innerHTML = '';
        document.getElementById('summaryModalDetailsListSku2').innerHTML = '';

        for (let i = 0; i < skuSelectedOptions.length; i++) {
            document.getElementById('summaryModalDetailsList').innerHTML += skuSelectedOptions[i];
        }

        // console.log(sku2SelectedOptions);
        for (let i = 0; i < sku2SelectedOptions.length; i++) {
            document.getElementById('summaryModalDetailsListSku2').innerHTML += sku2SelectedOptions[i];
        }

        this.populatePrintSummaryModal();
    }

    populatePrintSummaryModal() {
        document.getElementById('printPrice').innerHTML = document.getElementById('summaryModalPriceValue').innerHTML;
        document.getElementById('printDetailsList').innerHTML = document.getElementById('summaryModalDetailsList').innerHTML;
        document.getElementById('printItemNumberValueSku1').innerHTML = document.getElementById('sku').innerHTML;
        document.getElementById('printDetailsListSku2').innerHTML = document.getElementById('summaryModalDetailsListSku2').innerHTML;
        document.getElementById('printItemNumberValueSku2').innerHTML = document.getElementById('summaryModalSku2Value').innerHTML;
        document.getElementById('printProductLine').innerHTML = document.getElementById('headerProductLine').innerHTML;
        document.getElementById('printProductType').innerHTML = document.getElementById('headerProductType').innerHTML;

        // Non GLTF version
        // const corsCanvas = document.createElement('canvas');
        // // 4:3 resolution used by spins currently
        // corsCanvas.setAttribute('width', '1366');
        // corsCanvas.setAttribute('height', '1024');
        // const context = corsCanvas.getContext('2d');

        // var img = document.createElement('img');

        // img.onload = function () {
        //     context.drawImage(img, 0, 0);
        //     var url = corsCanvas.toDataURL("image/jpeg");
        //     document.getElementById('printImage').setAttribute('src', url);
        // };

        // img.crossOrigin = 'Anonymous';
        // img.src = document.getElementById('imageSpins').getAttribute('src');

        // document.getElementById('printImage').setAttribute('src', img);

        document.getElementById('printImage').setAttribute('src', this.gltfRenderer.mxtglTF.getScreenshotUrl());



                // show or hide second sku stuff
                if ($('#skuBreak').length == 0) {
                    $('.secondSku').hide();
                } else if ($('#skuBreak').length > 0) {
                    $('.secondSku').show();
                }
    }

    printSummary() {
        var printWindow = window.open('', 'PRINT', 'height=612,width=792');

        printWindow.document.write('<html><head><title>' + document.title + '</title>');
        // printWindow.document.write("<style>#printSummaryModalContent { text-align: left; position: absolute; width: 710px; height: 1005px; z-index: -100; background: white; padding: 25px 50px; position: absolute; top: 0px; left: 0px; font-family: Meta Medi; } hr { border: 1px solid #a6a6a6; } .item-number { clear:left; } #printBrand { color: #E22D00; font-weight: bold; font-size: 16px; margin-bottom: 0px; font-weight: normal; font-style: normal; font-stretch: normal; font-size: 14px; font-display: auto; } .printItemNumber { font-weight: bold; font-size: 10px; float: left; } .printItemNumberValue { float: right; font-size: 10px; } #printCopyright { font-size: 5px; position: absolute; right: 25px; bottom: 50px; } #printItemNumberValue { color: #252525; } #printDetails { font-weight: bold; font-size: 10px; margin-top: 130px; } #printDetailsSku2 { font-weight: bold; font-size: 10px; margin-top: 130px; } #printProductType { font-size: 13px; } #printProductLine { margin-bottom: 0px; font-size: 13px; } #printImage { width: 355px; position: absolute; top: 25px; right: 50px; } #printPrice { font-weight: bold; margin-bottom: 0px; } #printDetailsList { font-size: 8px; } #printEstimatedListPrice { font-size: 8px; } #pageContainer { background: white; z-index: 0; top: 0; left: 0; bottom: 0; right: 0; } @page{ size:auto; margin:0; }</style>");
        printWindow.document.write('<link rel="stylesheet" type="text/css" href="./css/vars.css"><link rel="stylesheet" type="text/css" href="./css/fonts.css"><link rel="stylesheet" type="text/css" href="./css/main.css"><link rel="stylesheet" type="text/css" href="./css/configurator.css">');
        if (this.productType == "Table") {
            printWindow.document.write('<link rel="stylesheet" type="text/css" href="./css/geiger.css">');
        }
        printWindow.document.write('</head><body >');
        printWindow.document.write(document.getElementById('printSummaryPDF').innerHTML);
        printWindow.document.write('</body></html>');

        printWindow.document.close(); // necessary for IE >= 10
        printWindow.focus(); // necessary for IE >= 10*/

        setTimeout(() => { // needs a delay to load CSS & font files
            printWindow.print();
            // printWindow.close();
        }, 100);

        return false;
    }

    setProductHeaders(productJSON) {
        $('.product-header').html(productJSON.header);
        $('.product-subheader').html(productJSON.subheader);
    }

    addDCOComponent(dco,skuArray,optionObject)
    {
        if(optionObject.dependent_step_types)
        {
            // console.log("using dependent step types");
            for(let d=0;d<optionObject.dependent_step_types.length;d++)
            {
                const dst = optionObject.dependent_step_types[d];
                // console.log("checking dst step:" + stepObject.step_id + ":dst:" + dst.step_id + ":" + dst.option_id);
                let matched = false;
                for(let ds=0;ds<skuArray.length;ds++)
                {
                    if(dst.step_id==skuArray[ds].step_id && dst.option_id==skuArray[ds].option_id)
                    {
                        // console.log("matched");
                        matched = true;
                        if(dst.render_product_ids.length>1)
                        {
                            //treat like parallel arrays
                            for(let st=0;st<dst.render_step_types.length;st++)
                            {
                                let dcoComponent = {};
                                dcoComponent.stepType = dst.render_step_types[st];
                                dcoComponent.macType = null;
                                dcoComponent.matchType = 0;
                                if(this.suspensionID>-1 && dst.render_product_ids[st].indexOf("HRMCHAIR-UPHOLSTERY-CANYON")>-1)
                                {
                                    // console.log("setting canyon suspension1");
                                    dcoComponent.productID = dst.render_product_ids[st] + "_" + this.suspensionID;
                                }
                                else
                                {
                                    dcoComponent.productID = dst.render_product_ids[st];                                    
                                }
                                dcoComponent.remove = false;
                                dco.push(dcoComponent);
                            }
                        }
                        else
                        {
                            //apply render product id to all render step types
                            for(let st=0;st<dst.render_step_types.length;st++)
                            {
                                let dcoComponent = {};
                                dcoComponent.stepType = dst.render_step_types[st];
                                dcoComponent.macType = null;
                                dcoComponent.matchType = 0;
                                if(this.suspensionID>-1 && dst.render_product_ids[0].indexOf("HRMCHAIR-UPHOLSTERY-CANYON")>-1)
                                {
                                    // console.log("setting canyon suspension2");
                                    dcoComponent.productID = dst.render_product_ids[0] + "_" + this.suspensionID;
                                }
                                else
                                {
                                    dcoComponent.productID = dst.render_product_ids[0];                                    
                                }
                                dcoComponent.remove = false;
                                dco.push(dcoComponent);
                            }
                        }
                        break;
                    }
                }
                if(matched)
                {
                    break;
                }
            }
        }

        if(optionObject.render_product_ids.length>1)
        {
            //treat like parallel arrays
            // console.log("setting dco with parallel arrays:" + optionObject.option_id + ":" + optionObject.name);
            for(let st=0;st<optionObject.render_step_types.length;st++)
            {
                let dcoComponent = {};
                dcoComponent.stepType = optionObject.render_step_types[st];
                dcoComponent.macType = null;
                dcoComponent.matchType = 0;
                if(this.suspensionID>-1 && optionObject.render_product_ids[st].indexOf("HRMCHAIR-UPHOLSTERY-CANYON")>-1)
                {
                    // console.log("setting canyon suspension3");
                    dcoComponent.productID = optionObject.render_product_ids[st] + "_" + this.suspensionID;
                }
                else
                {
                    // console.log("setting render product id:" + optionObject.render_product_ids[st]);
                    dcoComponent.productID = optionObject.render_product_ids[st];                                    
                }                
                dcoComponent.remove = false;
                dco.push(dcoComponent);
            }
        }
        else
        {
            //apply render product id to all render step types
            for(let st=0;st<optionObject.render_step_types.length;st++)
            {
                let dcoComponent = {};
                dcoComponent.stepType = optionObject.render_step_types[st];
                dcoComponent.macType = null;
                dcoComponent.matchType = 0;
                if(this.suspensionID>-1 && optionObject.render_product_ids[0].indexOf("HRMCHAIR-UPHOLSTERY-CANYON")>-1)
                {
                    // console.log("setting canyon suspension4");
                    dcoComponent.productID = optionObject.render_product_ids[0] + "_" + this.suspensionID;
                }
                else
                {
                    dcoComponent.productID = optionObject.render_product_ids[0];                                    
                }                
                dcoComponent.remove = false;
                dco.push(dcoComponent);
            }
        }
    }

    start(productJSON) {


        //clear the steps
        const divToAppendTo = document.getElementById('mxtConfigurator');
        for(let i=divToAppendTo.childNodes.length-1;i>=0;i--)
        {
            divToAppendTo.removeChild(divToAppendTo.childNodes[i]);
        }
        
        //clear the SKU
        this.sku = [];
        this.sku2 = [];
        this.skuBreak = false;
        this.priceInfo = [];
        
        if ( $('.product-header').text() == '') {
            this.setProductHeaders(productJSON);
        }
        
        //recursively update the form
        this.createStep(productJSON,this.lookupStep(productJSON,productJSON.rules.step_id),productJSON.rules.visible_option_order,productJSON.rules.default_option_id,productJSON.rules.option_rules,null,null);



        //clear out default child overrides
        let dco = [];

        //getting sku details and order, and generating default child overrides to call to render spins
        for(let i=0;i<this.sku.length;i++)
        {
            const skuPart = this.sku[i];
            const stepObject = this.lookupStep(productJSON,skuPart.step_id);
            const optionObject = this.lookupOption(stepObject,skuPart.option_id);
            if(optionObject!=null)
            {
                skuPart.sku = optionObject.sku_component;
                skuPart.sku_order = stepObject.sku_order;
                skuPart.api_call = stepObject.api_call;

                // console.log("setting dco:" + stepObject.name + ":" + skuPart.sku);
                this.addDCOComponent(dco,this.sku,optionObject);
            }
            else
            {
                skuPart.sku_order = 1000;
            }
            
        }

        // console.log("dco length:" + dco.length);

        for(let i=0;i<this.sku2.length;i++)
        {
            const skuPart = this.sku2[i];
            const stepObject = this.lookupStep(productJSON,skuPart.step_id);
            const optionObject = this.lookupOption(stepObject,skuPart.option_id);
            if(optionObject!=null)
            {
                skuPart.sku = optionObject.sku_component;
                skuPart.sku_order = stepObject.sku_order;
                skuPart.api_call = stepObject.api_call;

                this.addDCOComponent(dco,this.sku2,optionObject);
            }
            else
            {
                skuPart.sku_order = 1000;
            }
        }

        // console.log("dco2 length:" + dco.length);

        // console.log("dco:" + JSON.stringify(dco));
        
        //sorting sku components
        this.sku.sort(function(skuPart1,skuPart2){         
            // console.log(skuPart1); 
            // console.log(skuPart2); 
            if(skuPart1.sku_order==skuPart2.sku_order)
                return 0;
            else if(skuPart1.sku_order<skuPart2.sku_order)
                return -1;
            else
                return 1;
        });

        this.sku2.sort(function(skuPart1,skuPart2){      
            // console.log(skuPart1); 
            // console.log(skuPart2); 
            if(skuPart1.sku_order==skuPart2.sku_order)
                return 0;
            else if(skuPart1.sku_order<skuPart2.sku_order)
                return -1;
            else
                return 1;
        });

        //building final sku as a string
        let rootSKU = "";
        let finishSKU = "";
        let sku2SKU = "";
        for(let i=0;i<this.sku.length;i++)
        {
            // console.log("sku:" + this.sku[i].sku_order + ":" + this.sku[i].sku);
            if(this.sku[i].api_call=="Root")
            {
                rootSKU += this.sku[i].sku;
            }
            if(this.sku[i].sku)
            {
                finishSKU += this.sku[i].sku;
            }
        }

        for(let i=0;i<this.sku2.length;i++)
        {
            // console.log("sku:" + this.sku[i].sku_order + ":" + this.sku[i].sku);
            sku2SKU += this.sku2[i].sku;
        }

        

        //render spins
        if (this.enqueueProductToSpinsServer != null) {
            const dcoToString = JSON.stringify(dco);
            // console.log(dcoToString);
            // console.log("enqueueProduct:" + finishSKU + ":" + this.chairJSON.product + ":" + dcoToString);
            let suspensionKey = "";
            if(this.suspensionID>-1)
            {
                suspensionKey = this.suspensionID;
            }

            this.enqueueProductToSpinsServer(finishSKU+sku2SKU+suspensionKey, this.chairJSON.product, dcoToString);

        }


        document.getElementById('sku').innerText = finishSKU;

        const baseurl = window.location.href.split('?sku=')[0];

        
        if(sku2SKU.length>0)
        {
            document.getElementById('sku2').innerText = sku2SKU;
            history.replaceState('{}', '', baseurl + '?sku=' + finishSKU + "&sku2=" + sku2SKU);
        }
        else
        {
            history.replaceState('{}', '', baseurl + '?sku=' + finishSKU);
        }
        
        

        
        
        

        

        //update price
        // this.getProductData(rootSKU,finishSKU,sku2SKU);
        

    }

    getSwatchDetails(step_id,option_id,material_id) {
        if (material_id != null) {

            const productEndpoint = `${this.apiBaseURL}proxy_material_service/1.0/${material_id}/`;

            // console.log("swatch api:" + productEndpoint);
            var request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState == 4 && request.status == 200) {

                    const theResponseText = request.responseText;
                    if (theResponseText.startsWith('error')) {

                        // console.log('Unable to get product options for material id: ' + material_id + ' ' + theResponseText);

                    } else {

                        let jsonObject = this.cleanupJson(JSON.parse(theResponseText));
                        // console.log(JSON.stringify(jsonObject));
                        const abrasion = jsonObject.abrasion;
                        const priceCategory = jsonObject.priceCategory;
                        const content = jsonObject.content;
                        const swatchURL = jsonObject.downLoadLink2D;

                        this.updateSwatch(step_id,option_id,material_id,abrasion,priceCategory,content,swatchURL);
                        
                        
                    }
                }
            }
            request.open("GET", productEndpoint, true);
            request.send();
        }
    }        

    updateSwatch(step_id,option_id,material_id,abrasion,priceCategory,content,swatchURL)
    {
        // console.log("updateSwatch:" + step_id + ":" + option_id + ":" + material_id + ":" + abrasion + ":" + priceCategory + ":" + content + ":" + swatchURL);
        
        let swatchModalToSelect = null;
        let swatchModals = $('.swatchDetailsModal');
        for (let i = 0; i < swatchModals.length; i++) {
            // console.log(swatchModals[i].getAttribute('data-swatchmodalstepid'));
            if (swatchModals[i].getAttribute('data-swatchmodalstepid') == step_id) {
                // console.log(swatchModals[i].getAttribute('data-swatchmodalstepoptionid'));
                if (swatchModals[i].getAttribute('data-swatchmodalstepoptionid') == option_id) {
                    swatchModalToSelect = swatchModals[i];
                }
            }
        }

        // console.log(swatchModalToSelect);

        $(swatchModalToSelect).find('.durabilityValue').text(abrasion);
        $(swatchModalToSelect).find('.skuValue').text(material_id);
        $(swatchModalToSelect).find('.contentValue').text(content);
        $(swatchModalToSelect).find('.priceValue').text(priceCategory);
        $(swatchModalToSelect).find('.swatchModalImage').attr('src', swatchURL);

    }

    getRootProductData(rootSKU,sku2SKU) {
        if (rootSKU) {
            const productEndpoint = `${this.apiBaseURL}jobs/proxy_get_product_option/1.0/${rootSKU}/`;

            // console.log("root pricing api:" + productEndpoint);
            var request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState == 4 && request.status == 200) {

                    const theResponseText = request.responseText;
                    if (theResponseText.startsWith('error')) {

                        // console.log('Unable to get product options for SKU: ' + rootSKU + ' ' + theResponseText);

                    } else {

                        let jsonObject = this.cleanupJson(JSON.parse(theResponseText));

                        if(jsonObject.features && jsonObject.features.length>0 && jsonObject.features[0].productAlert && jsonObject.features[0].productAlert.link)
                        {
                            this.createProductAlert(jsonObject.features[0].productAlert.link.text,jsonObject.features[0].productAlert.link.URL,jsonObject.features[0].productAlert.preLinkText,jsonObject.features[0].productAlert.postLinkText);
                        }
                        // console.log(JSON.stringify(jsonObject));
                        // console.log("price return:" + jsonObject.priceReturn);
                        
                        // if(this.chairJSON.pricing_level=="option")
                        // {
                            for(let f=0;f<jsonObject.features.length;f++)
                            {

                                for(let i=0;i<this.priceInfo.length;i++)
                                {
                                    if(this.priceInfo[i].api=="Root" && this.priceInfo[i].featureCode==jsonObject.features[f].featureCode && !this.priceInfo[i].priceSet)
                                    {                                                                        
                                        // console.log("price info root:" + this.priceInfo[i].step_id + ":" + this.priceInfo[i].option_id + ":" + this.priceInfo[i].api + ":" + this.priceInfo[i].featureCode + ":" + this.priceInfo[i].sku_component);                            
                                        for(let op=0;op<jsonObject.features[f].optionCodes.entry.length;op++)
                                        {                                            
                                            const stepObject = this.lookupStep(this.chairJSON,this.priceInfo[i].step_id);                                            
                                            let hasHelpText = false;
                                            for(let s=0;s<stepObject.options.length;s++)
                                            {
                                                if(stepObject.options[s].sku_component==jsonObject.features[f].optionCodes.entry[op].key)
                                                {

                                                    // console.log("option:" + jsonObject.features[f].optionCodes.entry[op].value.featureOptionUI + ":help text:" + jsonObject.features[f].optionCodes.entry[op].value.featureOptionHelpText);
                                                    stepObject.options[s].featureOptionUI = jsonObject.features[f].optionCodes.entry[op].value.featureOptionUI;
                                                    stepObject.options[s].featureOptionHelpText = jsonObject.features[f].optionCodes.entry[op].value.featureOptionHelpText;
                                                    // console.log("option price:" + stepObject.options[s].option_id + ":" + jsonObject.features[f].optionCodes.entry[op].value.optionPrice);                     


                                                    if(stepObject.options[s].featureOptionHelpText && stepObject.options[s].featureOptionUI && stepObject.options[s].featureOptionHelpText.length>0 && stepObject.options[s].featureOptionUI.length>0)
                                                    {
                                                        hasHelpText = true;
                                                    }


                                                    let priceString = jsonObject.features[f].optionCodes.entry[op].value.optionPrice.toString();

                                                    if (priceString.indexOf('-') > -1) {
                                                        priceString = priceString.replace('-', '');
                                                        priceString = " - $" + priceString + ".00";
                                                    } else {
                                                        priceString = " + $" + priceString + ".00";
                                                    }
                                                    
                                                    // console.log('Price String: ');
                                                    // console.log(priceString);
                                                    
                                                    let step = $("#" + stepObject.step_id);
                                                    let option = $(step).find("[data-option-id='" + stepObject.options[s].option_id + "']"); 
                                                    let inlinePrice = $(option).find('[data-price]');
                                                    inlinePrice.text(priceString);
                                                    inlinePrice.hide();

                                                    if (priceString == " + $0.00" || priceString == " - $0.00" || priceString == "+ $0.00" || priceString == "- $0.00" ) {
                                                        inlinePrice.prop('style', 'display: none;');
                                                    } else {
                                                        inlinePrice.prop('style', 'display: block;');
                                                    }
                                                    
                                                    if(stepObject.ui=="Swatch")
                                                    {
                                                        let hoverTitle = $(option).prop('title');
                                                        if (hoverTitle){
                                                            
                                                            if (priceString == " + $0.00" || priceString == " - $0.00") {
                                                                // $(option).attr('data-original-title', hoverTitle);
                                                                $(option).attr('title', hoverTitle);
                                                                // $(option).attr('title', hoverTitle).tooltip('fixTitle').tooltip('show');                                                            
                                                            } else {
                                                                // $(option).attr('title', hoverTitle + priceString).tooltip('fixTitle').tooltip('show');
                                                                // $(option).attr('data-original-title', hoverTitle+priceString);
                                                                $(option).attr('title', hoverTitle+priceString);
                                                            }

                                                            

                                                            // console.log(inlinePrice);

                                                            
                                                            inlinePrice.prop('style', 'display: none;');
                                                        }
                                                        else {
                                                            hoverTitle = $(option).data('originalTitle');

                                                            if (priceString == " + $0.00" || priceString == " - $0.00") {
                                                                // $(option).attr('data-original-title', hoverTitle);
                                                                $(option).tooltip('hide').attr('data-original-title', hoverTitle).tooltip('show');
                                                                // $(option).attr('title', hoverTitle).tooltip('fixTitle').tooltip('show');                                                            
                                                            } else {
                                                                // $(option).attr('title', hoverTitle + priceString).tooltip('fixTitle').tooltip('show');
                                                                // $(option).attr('data-original-title', hoverTitle+priceString);
                                                                $(option).tooltip('hide').attr('data-original-title', hoverTitle+priceString).tooltip('show');
                                                            }

                                                            

                                                            // console.log(inlinePrice);

                                                            
                                                            inlinePrice.prop('style', 'display: none;');
                                                        }
                                                    }
                                                }
                                                
                                            }

                                            if(hasHelpText)
                                            {
                                                let button = document.getElementById('stepHeading:' + stepObject.step_id);                                                
                                                button.style.display = "block";
                                            }

                                            if(jsonObject.features[f].optionCodes.entry[op].key==this.priceInfo[i].sku_component)
                                            {
                                                this.priceInfo[i].option_price = jsonObject.features[f].optionCodes.entry[op].value.optionPrice;
                                                // console.log("selected option price:" + this.priceInfo[i].option_price);                            
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                        // }

                        if(this.skuBreak)
                        {
                            this.getSKU2Data(sku2SKU);
                        }
                        else
                        {
                            this.displayPrice(0);
                        }

                        




                        //call a method to iterate through price info array and update the option price for each step_id,option_id combination

                        // this.setPrice(jsonObject.priceReturn);
                        
                    }
                }
            }
            request.open("GET", productEndpoint, true);
            request.send();
        }
    }    

    displayPrice(sku2Price)
    {
        let totalPrice = this.currentPrice;
        // console.log("price info current price:" + totalPrice);
        for(let i=0;i<this.priceInfo.length;i++)
        {
            // console.log("price info display price:" + this.priceInfo[i].option_price + ":" + this.priceInfo[i].sku_component + ":" + this.priceInfo[i].step_name + ":" + this.priceInfo[i].option_name);
            if(this.priceInfo[i].option_price)
            {
                // console.log("price info adding option price:" + this.priceInfo[i].option_price);
                totalPrice += parseInt(this.priceInfo[i].option_price);
            }
        }
        // console.log("price info set total price:" + totalPrice);
        totalPrice += sku2Price;
        this.setTotalPrice(totalPrice);


        if (this.newTotalPrice!=this.previousPrice) {
            let priceAnimation = '';
            
            
            const basePrice = $("#basePrice").text();
            const priceChange = (this.newTotalPrice-this.previousPrice);
            if(priceChange>0)
            {
                this.setPriceAnimation("+$" + Math.abs(priceChange).toFixed(2));
            }
            else
            {
                this.setPriceAnimation("-$" + Math.abs(priceChange).toFixed(2));
            }
        }
    }
    
    getProductData(rootSKU,finishSKU,sku2SKU) {
        // console.log('FINISH SKU');
        // console.log(finishSKU);
        if (finishSKU) {
            const productEndpoint = `${this.apiBaseURL}jobs/proxy_get_product_option/1.0/${finishSKU}/`;

            console.log("feature pricing api:" + productEndpoint);
            var request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState == 4 && request.status == 200) {

                    const theResponseText = request.responseText;
                    if (theResponseText.startsWith('error')) {

                        // console.log('Unable to get product options for SKU: ' + finishSKU + ' ' + theResponseText);

                    } else {

                        let jsonObject = this.cleanupJson(JSON.parse(theResponseText));
                        // console.log('Price Info: ');
                        // console.log(jsonObject);

                        // Set the returned price from the API for the full SKU 
                        // console.log("price return:" + jsonObject.priceReturn);
                        // if (this.isFirstPriceCall) {
                            this.setBasePrice(jsonObject.priceReturn);
                            // this.setTotalPrice(jsonObject.priceReturn);
                            this.currentPrice = jsonObject.priceReturn;
                        //     this.isFirstPriceCall = false;
                        // }
                        
                        //clearing pricinfo
                        for(let i=0;i<this.priceInfo.length;i++)
                        {
                            this.priceInfo[i].priceSet = false;
                        }

                        // Set inline pricing options
                        // if(this.chairJSON.pricing_level=="option")
                        // {
                            for(let f=0;f<jsonObject.features.length;f++)
                            {
                                for(let i=0;i<this.priceInfo.length;i++)
                                {
                                    if(this.priceInfo[i].api=="Finish" && this.priceInfo[i].featureCode==jsonObject.features[f].featureCode && !this.priceInfo[i].priceSet)
                                    {                                                                                                                
                                        // console.log("price info gpd:" + this.priceInfo[i].step_id + ":" + this.priceInfo[i].option_id + ":" + this.priceInfo[i].api + ":" + this.priceInfo[i].featureCode + ":" + this.priceInfo[i].sku_component);                            
                                        for(let op=0;op<jsonObject.features[f].optionCodes.entry.length;op++)
                                        {
                                            // console.log("option code:" + jsonObject.features[f].optionCodes.entry[op].key + ":" + jsonObject.features[f].optionCodes.entry[op].value.optionPrice);
                                            const stepObject = this.lookupStep(this.chairJSON,this.priceInfo[i].step_id);        
                                            let hasHelpText = false;                                    
                                            for(let s=0;s<stepObject.options.length;s++)
                                            {
                                                if(stepObject.options[s].sku_component==jsonObject.features[f].optionCodes.entry[op].key)
                                                {

                                                    // console.log("step type:" + stepObject.ui);

                                                    
                                                    stepObject.options[s].featureOptionUI = jsonObject.features[f].optionCodes.entry[op].value.featureOptionUI;
                                                    stepObject.options[s].featureOptionHelpText = jsonObject.features[f].optionCodes.entry[op].value.featureOptionHelpText;

                                                    if(stepObject.options[s].featureOptionHelpText && stepObject.options[s].featureOptionUI && stepObject.options[s].featureOptionHelpText.length>0 && stepObject.options[s].featureOptionUI.length>0)
                                                    {
                                                        hasHelpText = true;
                                                    }

                                                    
                                                    this.priceInfo[i].priceSet = true;
                                                    // console.log("option price:" + stepObject.options[s].option_id + ":" + jsonObject.features[f].optionCodes.entry[op].value.optionPrice);

                                                    let priceString = jsonObject.features[f].optionCodes.entry[op].value.optionPrice.toString();
                                                    // console.log("about to set price:" + priceString);

                                                    if (priceString.indexOf('-') > -1) {
                                                        priceString = priceString.replace('-', '');
                                                        priceString = " - $" + priceString + ".00";
                                                    } else {
                                                        priceString = " + $" + priceString + ".00";
                                                    }
                                                    
                                                    // console.log('Price String: ');
                                                    // console.log(priceString);
                                                    
                                                    let step = $("#" + stepObject.step_id);
                                                    let option = $(step).find("[data-option-id='" + stepObject.options[s].option_id + "']"); 
                                                    let inlinePrice = $(option).find('[data-price]');
                                                    inlinePrice.text(priceString);
                                                    inlinePrice.hide();

                                                    if (priceString == " + $0.00" || priceString == " - $0.00" || priceString == "+ $0.00" || priceString == "- $0.00" ) {
                                                        inlinePrice.prop('style', 'display: none;');
                                                    } else {
                                                        inlinePrice.prop('style', 'display: block;');
                                                    }
                                                    
                                                    if(stepObject.ui=="Swatch")
                                                    {
                                                        let hoverTitle = $(option).prop('title');
                                                        if (hoverTitle){
                                                            
                                                            if (priceString == " + $0.00" || priceString == " - $0.00") {
                                                                // $(option).attr('data-original-title', hoverTitle);
                                                                $(option).attr('title', hoverTitle);
                                                                // $(option).attr('title', hoverTitle).tooltip('fixTitle').tooltip('show');                                                            
                                                            } else {
                                                                // $(option).attr('title', hoverTitle + priceString).tooltip('fixTitle').tooltip('show');
                                                                // $(option).attr('data-original-title', hoverTitle+priceString);
                                                                $(option).attr('title', hoverTitle+priceString);
                                                            }

                                                            

                                                            // console.log(inlinePrice);

                                                            
                                                            inlinePrice.prop('style', 'display: none;');
                                                        }
                                                        else {
                                                            hoverTitle = $(option).data('originalTitle');

                                                            if (priceString == " + $0.00" || priceString == " - $0.00") {
                                                                // $(option).attr('data-original-title', hoverTitle);
                                                                $(option).tooltip('hide').attr('data-original-title', hoverTitle).tooltip('show');
                                                                // $(option).attr('title', hoverTitle).tooltip('fixTitle').tooltip('show');                                                            
                                                            } else {
                                                                // $(option).attr('title', hoverTitle + priceString).tooltip('fixTitle').tooltip('show');
                                                                // $(option).attr('data-original-title', hoverTitle+priceString);
                                                                $(option).tooltip('hide').attr('data-original-title', hoverTitle+priceString).tooltip('show');
                                                            }

                                                            

                                                            // console.log(inlinePrice);

                                                            
                                                            inlinePrice.prop('style', 'display: none;');
                                                        }
                                                    }

                                                    // console.log("done!");


                                                }
                                            }

                                            if(hasHelpText)
                                            {
                                                let button = document.getElementById('stepHeading:' + stepObject.step_id);                                                
                                                button.style.display = "block";
                                            }

                                            if(jsonObject.features[f].optionCodes.entry[op].key==this.priceInfo[i].sku_component)
                                            {
                                                this.priceInfo[i].option_price = jsonObject.features[f].optionCodes.entry[op].value.optionPrice;
                                                // console.log("selected option price:" + this.priceInfo[i].option_price);                            
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                        // }


                        this.getRootProductData(rootSKU,sku2SKU);
                        // this.setPrice(jsonObject.priceReturn);
                        
                    }
                }
            }
            request.open("GET", productEndpoint, true);
            request.send();
        }
    }

    getSKU2Data(sku2SKU) {
        if (sku2SKU) {
            const productEndpoint = `${this.apiBaseURL}jobs/proxy_get_product_option/1.0/${sku2SKU}/`;

            // console.log("sku2sku pricing api:" + productEndpoint);
            var request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState == 4 && request.status == 200) {

                    const theResponseText = request.responseText;
                    if (theResponseText.startsWith('error')) {

                        // console.log('Unable to get product options for SKU: ' + sku2SKU + ' ' + theResponseText);

                    } else {

                        let jsonObject = this.cleanupJson(JSON.parse(theResponseText));
                        // console.log('SKU2 Price Info: ');
                        // console.log(jsonObject);

                        // Set the returned price from the API for the full SKU 
                        // console.log("sku2 price return:" + jsonObject.priceReturn);


                        this.displayPrice(jsonObject.priceReturn);
                        
                        
                    }
                }
            }
            request.open("GET", productEndpoint, true);
            request.send();
        }
    }    

    cleanupJson(json) {
        $.each(json, (key, value) => {
            if (value === "" || value === null) {
                delete json[key];
            } else if (Array.isArray(value) && value.length == 0) {
                delete json[key];
            } else if (typeof (value) === "object") {
                json[key] = this.cleanupJson(value);
            }
        });
        return json;
    }


    lookupStep(productJSON,step_id)
    {
        for(let i=0;i<productJSON.steps.length;i++)
        {
            if(productJSON.steps[i].step_id==step_id)
            {
                return productJSON.steps[i];
            }
                
        }

        return null;
    }

    lookupOption(step,option_id)
    {
        if(step.options)
        {
            for(let i=0;i<step.options.length;i++)
            {
                if(step.options[i].option_id==option_id)
                {
                    return step.options[i];
                }
            }
        }

        return null;
    }

    buildUIStepsAndOptions(configuratorJson) {
        // console.log(configuratorJson);
        for (let i = 0; i < configuratorJson.steps.length; i++) {
            let step = this.createStep(configuratorJson.steps[i]);
            let defaultOption = configuratorJson.rules.default_option_id;
            this.selectDefaultStepOptions(defaultOption, step, configuratorJson);
        }
    }

    showStep(createdStep) {
        createdStep.stepContainer.setAttribute('style', 'display: block');
    }

    hideNonConfigurableSteps(createdStep) {
        createdStep.stepContainer.setAttribute('style', 'display: none');
    }

    setCameraParameters(step_id,option_id)
    {
        const step = this.lookupStep(this.chairJSON,step_id);
        const option = this.lookupOption(step,option_id);
        // console.log("set camera parms:" + step_id + ":" + option_id);
        // if(option)
        // {
        //     console.log("option name:" + option.name);
        // }
        if(option && option.gltfFieldOfView)
        {

            this.prevFieldOfView = this.fieldOfView;
            this.prevCameraHeightOffset =this.cameraHeightOffset;
            this.prevCameraHeightOffsetForAngle = this.cameraHeightOffsetForAngle;
            this.prevCameraDepthOffset = this.cameraDepthOffset;
    
            this.fieldOfView = option.gltfFieldOfView;
            this.cameraHeightOffset = option.gltfCameraHeightOffset;
            this.cameraHeightOffsetForAngle = option.gltfCameraHeightOffsetForAngle;
            this.cameraDepthOffset = option.gltfCameraDepthOffset;

            // console.log("set camera parms:" + this.fieldOfView);
        }
        
    }

    getSelectedOption(step_id,default_option_id,visible_option_order)
    {
        // console.log("selected option selections count:" + step_id + ":" + this.selections.length);

        const stepObject = this.lookupStep(this.chairJSON,step_id);

        for(let i=0;i<this.selections.length;i++)
        {
            const selection = this.selections[i];
            // console.log("selection:" + selection.step_id + ":" + selection.option_id);
            if(selection.step_id==step_id)
            {                
                // console.log("step:" + step_id + ":" + visible_option_order + ":" + selection.option_id);
                if(visible_option_order.indexOf(selection.option_id)>-1)
                {                
                    // console.log("selected option returning selected");
                    //gather selected options for building SKU
                    let skuPart = {};
                    skuPart.step_id = step_id;                
                    skuPart.option_id = selection.option_id;
                    
                    if(stepObject.sku_index)                    
                        this.sku2.push(skuPart);
                    else
                        this.sku.push(skuPart);
                    // console.log("using selection");
                    this.setCameraParameters(step_id,selection.option_id);
                    return selection.option_id;
                }
                else
                {
                    break;
                }
            }
        }
        
        // console.log("selected option returning default");
        //gather selected options for building SKU
        let skuPart = {};
        skuPart.step_id = step_id;                
        skuPart.option_id = default_option_id;
        if(stepObject.sku_index)                    
            this.sku2.push(skuPart);
        else
            this.sku.push(skuPart);
        // console.log("using default");
        this.setCameraParameters(step_id,default_option_id);
        return default_option_id;
    }

    setSelectedOption(step_id,option_id)
    {
        // console.log("set selected option:" + step_id + "," + option_id);
        for(let i=0;i<this.selections.length;i++)
        {
            const selection = this.selections[i];
            if(selection.step_id==step_id)
            {
                selection.option_id = option_id;
                return;
            }
        }

        let selection = {};
        selection.step_id=step_id;
        selection.option_id=option_id;
        this.selections.push(selection);
    }

    displaySKU(id)
    {
        const divToAppendTo = document.getElementById('mxtConfigurator');
        let skuDiv = document.createElement("div");
        skuDiv.setAttribute("class","item-number");
        let skuP = document.createElement("p");
        skuP.innerText = "Item Number:";
        skuDiv.appendChild(skuP);
        let skuSpan = document.createElement("span");
        skuSpan.id = id;
        skuP.appendChild(skuSpan);
        divToAppendTo.appendChild(skuDiv);
    }

    
    createStepSwatchDetailsModal(stepContainer, stepID, stepOptionID, stepOptionJSON) {
        // console.log(stepOptionJSON);
        const modal = document.createElement('div');
        modal.setAttribute('class', 'swatchDetailsModal col-12');
        modal.setAttribute('data-swatchModalStepID', stepID);
        modal.setAttribute('data-swatchModalStepOptionID', stepOptionID);

        const modalCloseButton = document.createElement('button');
        modalCloseButton.setAttribute('class', 'swatchModalClose');
        modalCloseButton.innerHTML = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="icon___2lAjD" style="vertical-align: middle; cursor: pointer"><path id="CloseA" stroke="black" stroke-width="2" d="M20 4.38L18.619 3 11.5 10.12 4.381 3 3 4.38l7.12 7.12L3 18.62 4.381 20l7.12-7.12L18.618 20 20 18.62l-7.12-7.12z"></path><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="CloseB" fill="#fff"><use xlink:href="#CloseA"></use></mask><g fill="none" mask="url(#CloseB)"><path d="M0 0h24v24H0z"></path></g></g></svg>';
        modalCloseButton.addEventListener(this.clickTouchEvent, function (e) {
            e.preventDefault();
            $('.swatchDetailsModal').hide();
            document.body.removeAttribute('style');
        });
        modal.appendChild(modalCloseButton);

        const left = document.createElement('div');
        left.setAttribute('class', 'col-6 swatchModalHalf');
        const modalImg = document.createElement('img');
        modalImg.setAttribute('class', 'swatchModalImage img-fluid');
        left.appendChild(modalImg);
        
        const right = document.createElement('div');
        right.setAttribute('class', 'col-6 swatchModalHalf');
        const title = document.createElement('p');
        title.innerHTML = stepOptionJSON.name;
        title.setAttribute('class', 'titleValue');
        right.appendChild(title);
        const durabilityLabel = document.createElement('label');
        durabilityLabel.innerHTML = 'Durability';
        right.appendChild(durabilityLabel);
        const durabilityValue = document.createElement('p');
        durabilityValue.innerHTML = 'DURABILITY VALUE';
        durabilityValue.setAttribute('class', 'durabilityValue swatchModalValue');
        right.appendChild(durabilityValue);
        const contentLabel = document.createElement('label');
        contentLabel.innerHTML = 'Content';
        right.appendChild(contentLabel);
        const contentValue = document.createElement('p');
        contentValue.innerHTML = 'CONTENT VALUE';
        contentValue.setAttribute('class', 'contentValue swatchModalValue');
        right.appendChild(contentValue);
        const skuLabel = document.createElement('label');
        skuLabel.innerHTML = "SKU";
        right.appendChild(skuLabel);
        const skuValue = document.createElement('p');
        skuValue.innerHTML = 'SKU VALUE';
        skuValue.setAttribute('class', 'skuValue swatchModalValue');
        right.appendChild(skuValue);
        const priceLabel = document.createElement('label');
        priceLabel.innerHTML = 'Price Category';
        right.appendChild(priceLabel);
        const priceValue = document.createElement('p');
        priceValue.innerHTML = 'PRICE VALUE';
        priceValue.setAttribute('class', 'priceValue swatchModalValue');
        right.appendChild(priceValue);

        modal.appendChild(left);
        modal.appendChild(right);
        stepContainer.appendChild(modal);

        const values = {
            Image: modalImg,
            Durability : durabilityValue,
            Content: contentValue,
            Sku: skuValue,
            Price: priceValue
        };

        return values;
    }

    createProductAlert(text,url,preLinkText,postLinkText)
    {
        if(!text || text.length==0)
        {
            return;
        }
        let div = document.createElement("div");
        div.setAttribute("class","row");
        div.setAttribute("id","configurator-prompt");
        let pText = "";
        // console.log("prelinktext:" + preLinkText);
        let highlightText = "";
        for(let i=0;i<preLinkText.length;i++)
        {
            if(preLinkText[i])
            {
                if(i==0)
                {
                    highlightText = preLinkText[i];
                }
                else
                {
                    pText += preLinkText[i];
                }
            }
        }
        let aText = "";
        for(let i=0;i<postLinkText.length;i++)
        {
            if(postLinkText[i])
            {
                aText += postLinkText[i];
            }
        }
        let alert = '<span class="Shopping-Online">' + highlightText + '</span>' + pText + ' <a href="' + url + '" target="__blank">' + text + '</a> ' + aText;
        div.innerHTML = '<div class="col-12"><div class="shopping-online-tip configurator-prompt-box"><div class="row"><div class="col-2 col-lg-1"><svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 512 512" class="shoppingCart___rlUec" style="vertical-align: middle;"><g id="shopping-cart"><path d="M153,408c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S181.05,408,153,408z M0,0v51h51l91.8,193.8L107.1,306 c-2.55,7.65-5.1,17.85-5.1,25.5c0,28.05,22.95,51,51,51h306v-51H163.2c-2.55,0-5.1-2.55-5.1-5.1v-2.551l22.95-43.35h188.7 c20.4,0,35.7-10.2,43.35-25.5L504.9,89.25c5.1-5.1,5.1-7.65,5.1-12.75c0-15.3-10.2-25.5-25.5-25.5H107.1L84.15,0H0z M408,408 c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S436.05,408,408,408z"></path></g></svg></div><p class="col-8 col-lg-10" style="margin-top: 0px; margin-bottom: 0px;">' + alert + '</p><div class="col-2 col-lg-1 close-shopping-online-tip"><svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="icon___2lAjD" style="vertical-align: middle; cursor: pointer"><path id="CloseA" stroke="black" stroke-width="2" d="M20 4.38L18.619 3 11.5 10.12 4.381 3 3 4.38l7.12 7.12L3 18.62 4.381 20l7.12-7.12L18.618 20 20 18.62l-7.12-7.12z"></path><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="CloseB" fill="#fff"><use xlink:href="#CloseA"></use></mask><g fill="none" mask="url(#CloseB)"><path d="M0 0h24v24H0z"></path></g></g></svg></div></div></div></div>';                        
        const divToAppendTo = document.getElementById("configPanel");
        const prevDiv = document.getElementById("configurator-header-product-category");
        for(let i=0;i<divToAppendTo.childNodes.length;i++)
        {
            if(divToAppendTo.childNodes[i]==prevDiv && i+1<divToAppendTo.childNodes.length)
            {
                if(divToAppendTo.childNodes[i+1].id=="configurator-prompt")
                {
                    divToAppendTo.removeChild(divToAppendTo.childNodes[i+1]);
                }
                break;
            }
        }
        for(let i=0;i<divToAppendTo.childNodes.length;i++)
        {
            if(divToAppendTo.childNodes[i]==prevDiv && i+1<divToAppendTo.childNodes.length)
            {
                divToAppendTo.insertBefore(div,divToAppendTo.childNodes[i+1]);
                break;
            }
        }


        
        $('.close-shopping-online-tip').click(function (e) {
            $(this).closest('.shopping-online-tip').hide();
        });
    }


    // Step creation methods
    createStep(productJSON,stepJSON,visible_option_order,default_option_id,option_rules,api,featureCode) {
        
        const divToAppendTo = document.getElementById('mxtConfigurator');

        const selected_option_id = this.getSelectedOption(stepJSON.step_id,default_option_id,visible_option_order);

        const optionObject = this.lookupOption(stepJSON,selected_option_id);

        const baseURL = './';
        const selectedImgURL = baseURL + this.productType + '/' + this.productType + '_' + stepJSON.step_id + '_' + optionObject.option_id + '.jpg';
        const selectedLabel = optionObject.name;

        const that = this;

        // console.log("creating step:" + stepJSON.step_id + ":" + selectedImgURL + ":" + selectedLabel);

        if(stepJSON.ui == "Item Number")
        {
            if(stepJSON.name=="1")
            {
                this.displaySKU("sku");
            }
            else
            {
                this.skuBreak = true;
                this.displaySKU("sku2");
            }
        }
        else if(stepJSON.ui == "Message")
        {            

            let div = document.createElement("div");
            div.setAttribute("id","skuBreak");
            
            
            div.innerHTML = '<div class="row" id="configurator-prompt"><div class="col-12"><div class="shopping-online-tip configurator-prompt-box"><div class="row"><div class="col-2 col-lg-1"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><g id="Bounding_Boxes" display="none"><g id="ui_x5F_spec_x5F_header_copy_3" display="inline"></g><path display="inline" fill="none" d="M0,0h24v24H0V0z"/></g><g id="Outline"><g id="ui_x5F_spec_x5F_header"></g><path d="M11,15h2v2h-2V15z M11,7h2v6h-2V7z M11.99,2C6.47,2,2,6.48,2,12s4.47,10,9.99,10C17.52,22,22,17.52,22,12S17.52,2,11.99,2zM12,20c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8S16.42,20,12,20z"/></g></svg></div><p class="col-8 col-lg-10" style="margin-top: 0px; margin-bottom: 0px;">' + stepJSON.name + '</p><div class="col-2 col-lg-1 close-shopping-online-tip"><svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="icon___2lAjD" style="vertical-align: middle; cursor: pointer"><path id="CloseA" stroke="black" stroke-width="2" d="M20 4.38L18.619 3 11.5 10.12 4.381 3 3 4.38l7.12 7.12L3 18.62 4.381 20l7.12-7.12L18.618 20 20 18.62l-7.12-7.12z"></path><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="CloseB" fill="#fff"><use xlink:href="#CloseA"></use></mask><g fill="none" mask="url(#CloseB)"><path d="M0 0h24v24H0z"></path></g></g></svg></div></div></div></div></div>';                        


            divToAppendTo.appendChild(div);


            $('.close-shopping-online-tip').click(function (e) {
                $(this).closest('.shopping-online-tip').hide();
            });
            return;
        }
        else
        {
        
            const step = {};

            step.stepId = stepJSON.step_id;
            step.stepContainer = this.createStepForm(stepJSON.name, step.stepId);
            step.stepHeadingContainer = this.createStepHeadingContainer(step.stepContainer, stepJSON,selectedImgURL,selectedLabel);

            step.stepHeadingContainer.addEventListener(this.clickTouchEvent, function(e) {
                // e.preventDefault();
                if(that.openStep && that.openStep==step.stepId)
                {
                    that.openStep = null;
                }
                else
                {
                    that.openStep = step.stepId;
                }

                that.start(that.chairJSON);
            });

            step.stepSelectionMobileOnly = this.createStepSelectionMobileOnly(step.stepContainer);
            if (stepJSON.subhead != '') {
                step.stepSubheadingContainer = this.createStepSubheadingContainer(step.stepSelectionMobileOnly.button, stepJSON);
                if (stepJSON.ui == "Swatch") {
                    step.stepCurrentSwatchHeading = this.createStepCurrentSwatchHeading(step.stepContainer, true);
                }
            }
            else {
                if (stepJSON.ui == "Swatch") {
                    step.stepCurrentSwatchHeading = this.createStepCurrentSwatchHeading(step.stepContainer, false);
                }       
            }
            step.stepHeadingLabel = this.createStepHeadingLabel(stepJSON.name, step.stepHeadingContainer);


            if (stepJSON.ui != "None") {
                const stepHeadingSelectedLabel = document.createElement('label');
                const stepHeadingSelectedImage = document.createElement('img');
        
                
                stepHeadingSelectedLabel.setAttribute('class','selectedLabel');
                stepHeadingSelectedImage.setAttribute('class','selectedThumb');
        
        
                stepHeadingSelectedLabel.innerHTML = selectedLabel;
                stepHeadingSelectedImage.src = selectedImgURL;
        
                step.stepHeadingContainer.appendChild(stepHeadingSelectedLabel);
                step.stepHeadingContainer.appendChild(stepHeadingSelectedImage);
        
                const rightArrow = document.createElement('img');
                rightArrow.setAttribute('class','rightArrow');
                rightArrow.src = './../css/icons-misc-dropdown.svg';
        

                step.stepHeadingContainer.appendChild(rightArrow);
            }

            
            step.stepHeadingHelpButton = this.createStepHeadingHelpButton(step.stepHeadingContainer,step.stepId);
            step.stepHeadingHelpDiv = this.createStepHeadingHelpDiv(step.stepContainer, step.stepHeadingHelpButton, stepJSON.productStepHelpText);
            step.stepOptions = [];
            step.selectedOption = null;
            step.selectedOptionPriceOffset = null;
            step.uiType = stepJSON.ui;

            


                
            // console.log("get selected option:" + stepJSON.step_id + "," + selected_option_id);
            let optionGroup = "";
            if (stepJSON.ui == "Radio Group") {            
                for(let i=0;i<visible_option_order.length;i++)
                {                
                    const option_id = visible_option_order[i];
                    const stepOptionObject = this.lookupOption(stepJSON,option_id);
                    if(stepOptionObject.group && stepOptionObject.group.length>0 && stepOptionObject.group!=optionGroup)
                    {
                        optionGroup = stepOptionObject.group;
                        const stepHeadingGroup = document.createElement('div');
                        stepHeadingGroup.innerText = optionGroup;
                        stepHeadingGroup.setAttribute('class', 'optionGroup');
                        step.stepContainer.appendChild(stepHeadingGroup);
                    }
                    // console.log("creating step option:" + stepJSON.step_id + "," + option_id);
                    const stepOption = this.createStepOption(stepOptionObject, step.stepContainer, step, false,selected_option_id==option_id, stepJSON);
                    // console.log("displaying:" + stepJSON.step_id + ":" + option_id + ":selected:" + selected_option_id + ":api:" + api + ":featureCode:" + featureCode);
                    if(selected_option_id==option_id && api && featureCode)
                    {                    
                        // console.log("adding price info");
                        let priceInfoObject = {};
                        priceInfoObject.step_id = stepJSON.step_id;
                        priceInfoObject.option_id = option_id;
                        priceInfoObject.api = api;
                        priceInfoObject.step_name = stepJSON.name;
                        priceInfoObject.option_name = stepOptionObject.name;
                        priceInfoObject.sku_component = stepOptionObject.sku_component;
                        priceInfoObject.featureCode = featureCode;
                        this.priceInfo.push(priceInfoObject);
                    }
                    step.stepOptions.push(stepOption);

                    
                }
            } else if (stepJSON.ui == "Swatch") {
                for(let i=0;i<visible_option_order.length;i++)
                {
                    const option_id = visible_option_order[i];
                    const stepOptionObject = this.lookupOption(stepJSON,option_id);
                    if(stepOptionObject.group && stepOptionObject.group.length>0 && stepOptionObject.group!=optionGroup)
                    {
                        optionGroup = stepOptionObject.group;
                        const stepHeadingGroup = document.createElement('div');
                        stepHeadingGroup.innerText = optionGroup;
                        stepHeadingGroup.setAttribute('class', 'optionGroup');
                        step.stepContainer.appendChild(stepHeadingGroup);
                    }
                    // console.log("creating step option:" + stepJSON.step_id + "," + option_id);
                    const stepOption = this.createStepOption(stepOptionObject, step.stepContainer, step, true,selected_option_id==option_id, stepJSON);
                    // console.log("displaying:" + stepJSON.step_id + ":" + option_id + ":selected:" + selected_option_id + ":api:" + api + ":featureCode:" + featureCode);
                    if(selected_option_id==option_id && api && featureCode)
                    {
                        // console.log("adding price info");
                        let priceInfoObject = {};
                        priceInfoObject.step_id = stepJSON.step_id;
                        priceInfoObject.option_id = option_id;
                        priceInfoObject.api = api;
                        priceInfoObject.option_name = stepOptionObject.name;
                        priceInfoObject.step_name = stepJSON.name;
                        priceInfoObject.sku_component = stepOptionObject.sku_component;
                        priceInfoObject.featureCode = featureCode;
                        this.priceInfo.push(priceInfoObject);
                    }
                    step.stepOptions.push(stepOption);

                    if(stepOptionObject.material_id)
                    {
                        // console.log("getting swatch details:" + stepOptionObject.material_id);
                        // this.getSwatchDetails(stpepJSON.step_id,option_id,stepOptionObject.material_id);
                    }
                }
            } else if (stepJSON.ui == "None") {
                // console.log("Ignoring step: " + stepJSON.name);
                this.hideNonConfigurableSteps(step);
            }
        }
        

        for(let i=0;i<option_rules.length;i++)
        {
            const option_id = option_rules[i].option_id;
            const step_option_rules = option_rules[i].steps;
            if(stepJSON.ui == "Message" || stepJSON.ui == "Item Number" || option_id==selected_option_id)
            {
                for(let s=0;s<step_option_rules.length;s++)
                {
                    const step_option_rule = step_option_rules[s];
                    const stepObject = this.lookupStep(productJSON,step_option_rule.step_id);
                    this.createStep(productJSON,stepObject,step_option_rule.visible_option_order,step_option_rule.default_option_id,step_option_rule.option_rules,step_option_rule.api,step_option_rule.featureCode);
                }
            }
        }

        // console.log(step);
        return;
    }

    createStepForm(stepName, stepId) {
        let stepElementId = stepName.replace(/\s/g, '-');
        stepElementId = stepElementId.toLowerCase();
        const stepForm = document.createElement('form');
        stepForm.setAttribute('class', 'stepContainer');
        if(this.openStep==stepId)
        {
            stepForm.classList.add('show');
        }
        else
        {
            stepForm.classList.add('hide');
        }
        stepForm.setAttribute('id', stepId);
        stepForm.setAttribute('data-step-id', stepElementId);
        stepForm.setAttribute("onsubmit","return false;");

        // Hardcode the exact step order/positions
        let divToAppendTo;
    

        divToAppendTo = document.getElementById('mxtConfigurator');
        divToAppendTo.appendChild(stepForm);
        return stepForm;
    }

    createStepHeadingContainer(stepContainer, stepJSON,imgSrc,selectedLabel) {
        const stepHeadingContainer = document.createElement('button');
        stepHeadingContainer.setAttribute('class', 'stepHeadingContainer')
        stepContainer.appendChild(stepHeadingContainer);

        


        return stepHeadingContainer;
    }

    createStepSubheadingContainer(stepHeadingContainer, stepJSON) {
        const stepHeadingSubhead = document.createElement('p');
        stepHeadingSubhead.innerText = stepJSON.subhead;
        stepHeadingSubhead.setAttribute('class', 'subhead');
        // stepHeadingContainer.appendChild(stepHeadingSubhead);
        stepHeadingContainer.parentNode.insertBefore(stepHeadingSubhead, stepHeadingContainer.nextSibling);
        return stepHeadingSubhead;
    }

    createStepCurrentSwatchHeading(stepContainer, hasSwatchDetails) {
        let currentStepSwatch;
        if (hasSwatchDetails) {
            currentStepSwatch = document.createElement('button');
            currentStepSwatch.addEventListener(this.clickTouchEvent, function(e) {
                e.preventDefault();
                $('.swatchDetailsModal').hide();
                const swatchDetailModals = $('.swatchDetailsModal');
                for (let i = 0; i < swatchDetailModals.length; i++) {
                    if ($(swatchDetailModals[i]).find('.titleValue').text() == currentStepSwatch.innerText.replace(' ⓘ', '')) {
                        if (currentStepSwatch.classList.contains('swatchModalToggled')) {
                            $(swatchDetailModals[i]).hide();
                            currentStepSwatch.classList.remove('swatchModalToggled');
                        } else {
                            $(swatchDetailModals[i]).show();
                            currentStepSwatch.classList.add('swatchModalToggled');  
                        }
                    }
                }

                const detatchedModals = $('.swatchDetailsModal').detach();
                // console.log('look here');
                // console.log(detatchedModals);
                for (let i = 0; i < detatchedModals.length; i++) {
                    stepContainer.append(detatchedModals[i]);
                }

        });
        } else {
            currentStepSwatch = document.createElement('p');
        }
        currentStepSwatch.setAttribute('class', 'currentStepSwatch');
        currentStepSwatch.innerText = 'Current Swatch';
        stepContainer.appendChild(currentStepSwatch);

        return currentStepSwatch;
    }

    createStepSelectionMobileOnly(stepContainer) {
        const button = document.createElement('button');
        button.setAttribute('class', 'currentStepSelectionMobileOnly');
        stepContainer.appendChild(button);

        const img = document.createElement('img');
        img.setAttribute('class', 'currentStepSelectionImageMobileOnly');
        button.append(img);
        const p = document.createElement('p');
        p.setAttribute('class', 'currentStepSelectionLabelMobileOnly');
        button.appendChild(p);
        const arrow = document.createElement('img');
        arrow.setAttribute('class', 'currentStepSelectionArrowMobileOnly');
        arrow.setAttribute('src', ArrowRightIcon);
        button.append(arrow);
        button.addEventListener(this.clickTouchEvent, function(e) {
            // e.preventDefault();
            if (stepContainer.classList.contains('stepContainerHidden')) {
                arrow.classList.add('expanded');
                stepContainer.classList.remove('stepContainerHidden');
            } else {
                arrow.classList.remove('expanded');
                stepContainer.classList.add('stepContainerHidden');
            }
        });
        const mobileOnlyAccordion = {
            button: button,
            image: img,
            label: p,
            arrow: arrow
        }

        return mobileOnlyAccordion;
    }

    createStepHeadingLabel(stepText, stepHeadingContainer) {
        const stepHeadingLabel = document.createElement('label');
        stepHeadingLabel.setAttribute('class', 'stepHeadingLabel');
        stepHeadingLabel.innerText = stepText;
        stepHeadingContainer.appendChild(stepHeadingLabel);
        return stepHeadingLabel;
    }

    createStepHeadingHelpButton(stepHeadingContainer,step_id) {
        const stepHeadingHelpButton = document.createElement('button');
        stepHeadingHelpButton.setAttribute('class', 'stepHeadingHelpButton');
        stepHeadingHelpButton.setAttribute('id', 'stepHeading:' + step_id);
        stepHeadingHelpButton.style.display = "none";
        const stepHeadingHelpIcon = document.createElement('img');
        if (this.productType != "Table") {
            stepHeadingHelpIcon.setAttribute('src', HelpIcon);
        } else if (this.productType == "Table") {
            stepHeadingHelpIcon.setAttribute('src', GeigerHelpIcon);
        }
        stepHeadingHelpButton.appendChild(stepHeadingHelpIcon);
        stepHeadingHelpButton.innerHTML = stepHeadingHelpButton.innerHTML + ' Help';
        stepHeadingContainer.appendChild(stepHeadingHelpButton);
        return stepHeadingHelpButton;
    }

    createHelpColumn(columns,columnIndex,stepHeadingHelpDiv,imgSrc,labelTitle,helpText)
    {
        const columnOne = document.createElement('div');
        // columnOne.setAttribute('class', 'col-md-4 col-sm-12');
        columnOne.setAttribute('class', 'helpColumn');


        columnOne.setAttribute('style', 'float:left;');

        // console.log("columns:" + columns);
        if(columns==1 || this.iPhone || this.isAndroid)
        {
            console.log('iphone!');
            columnOne.style.width='100%';
        }
        else if(columns==2)
        {
            columnOne.style.width='50%';
        }
        else if(columns>2)
        {
            // console.log("setting width to 33%");
            columnOne.style.width = '33%';
        }

        if(columnIndex % 3==0)
        {
            columnOne.style.clear = 'left';
        }

        const columnOneImage = document.createElement('img');
        columnOneImage.setAttribute('src', imgSrc);
        columnOneImage.setAttribute('align','left');
        const columnOneHeading = document.createElement('h6');
        if(labelTitle)
        {
            columnOneHeading.innerText = labelTitle;
        }
        columnOneHeading.className = 'h6HelpHeader';
        columnOneHeading.style.width = '100%';
        columnOneHeading.style.maxWidth='250px';
        const columnOneText = document.createElement('p');
        columnOneText.style.width = '100%';
        columnOneText.style.maxWidth='250px';
        if(helpText)
        {
            columnOneText.innerText = helpText;
        }
        columnOne.appendChild(columnOneImage);
        columnOne.appendChild(columnOneHeading);
        columnOne.appendChild(columnOneText);
        stepHeadingHelpDiv.appendChild(columnOne);

        // const columnTwo = document.createElement('div');
        // columnTwo.setAttribute('class', 'col-md-4 col-sm-12');
        // columnTwo.setAttribute('style', 'float:left;');
        // const columnTwoImage = document.createElement('img');
        // columnTwoImage.setAttribute('src', '/');
        // const columnTwoHeading = document.createElement('h6');
        // columnTwoHeading.innerText = 'Label Title';
        // const columnTwoText = document.createElement('p');
        // columnTwoText.innerText = this.fillerText;
        // columnTwo.appendChild(columnTwoImage);
        // columnTwo.appendChild(columnTwoHeading);
        // columnTwo.appendChild(columnTwoText);
        // stepHeadingHelpDiv.appendChild(columnTwo);

        // const columnThree = document.createElement('div');
        // columnThree.setAttribute('class', 'col-md-4 col-sm-12');
        // columnThree.setAttribute('style', 'float:left;');
        // const columnThreeImage = document.createElement('img');
        // columnThreeImage.setAttribute('src', '/');
        // const columnThreeHeading = document.createElement('h6');
        // columnThreeHeading.innerText = 'Label Title';
        // const columnThreeText = document.createElement('p');
        // columnThreeText.innerText = this.fillerText;
        // columnThree.appendChild(columnThreeImage);
        // columnThree.appendChild(columnThreeHeading);
        // columnThree.appendChild(columnThreeText);
        // stepHeadingHelpDiv.appendChild(columnThree);
    }

    createStepHeadingHelpDiv(stepContainer, helpButton, helpText) {
        const stepHeadingHelpDivBackground = document.createElement('div');
        stepHeadingHelpDivBackground.setAttribute('class', 'stepHeadingHelpDivBackground displayNone');
        const stepHeadingHelpDiv = document.createElement('div');
        stepHeadingHelpDiv.setAttribute('class', 'stepHeadingHelpDiv');
        
        const stepHeadingHelpDivCloseButton = document.createElement('button');
        stepHeadingHelpDivCloseButton.setAttribute('class', 'stepHeadingHelpDivCloseButton');
        stepHeadingHelpDivCloseButton.innerHTML = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="icon___2lAjD" style="vertical-align: middle; cursor: pointer; width:15px;"><path id="CloseA" stroke="black" stroke-width="2" d="M20 4.38L18.619 3 11.5 10.12 4.381 3 3 4.38l7.12 7.12L3 18.62 4.381 20l7.12-7.12L18.618 20 20 18.62l-7.12-7.12z"></path><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="CloseB" fill="#fff"><use xlink:href="#CloseA"></use></mask><g fill="none" mask="url(#CloseB)"><path d="M0 0h24v24H0z"></path></g></g></svg>';
        stepHeadingHelpDivCloseButton.setAttribute('class', 'modalClose');
        stepHeadingHelpDiv.appendChild(stepHeadingHelpDivCloseButton);
        const stepHeadingHelpHeading = document.createElement('h2');
        stepHeadingHelpHeading.innerText = "Header Title";
        stepHeadingHelpHeading.setAttribute('class', 'stepHeadingHelpDivHeader');
        stepHeadingHelpDiv.appendChild(stepHeadingHelpHeading);




        // const stepHeadingHelpDivContent = document.createElement('p');

        // if (helpText == null) {
        //     stepHeadingHelpDivContent.innerText = this.fillerText;
        // } else {
        //     stepHeadingHelpDivContent.innerText = helpText;
        // }
        // stepHeadingHelpDiv.appendChild(stepHeadingHelpDivContent);

        stepHeadingHelpDivCloseButton.addEventListener(this.clickTouchEvent, (e) => {
            e.preventDefault(); // This shouldn't be needed, but something else was getting triggered on click as well...
            stepHeadingHelpDivBackground.setAttribute('class', 'stepHeadingHelpDiv displayNone');
            document.body.removeAttribute('style');
        });
        helpButton.addEventListener(this.clickTouchEvent, (e) => {
            e.preventDefault(); // This shouldn't be needed, but something else was getting triggered on click as well...

            //Set the contents of the dialog
            const helpStep = e.currentTarget.id.substring(e.currentTarget.id.indexOf(":")+1);

            const stepObject = this.lookupStep(this.chairJSON,helpStep);

            stepHeadingHelpHeading.innerText = stepObject.name;
            // console.log("help step:" + helpStep);

            const baseURL = './';
            
            for(let i=stepHeadingHelpDiv.childNodes.length-1;i>=0;i--)
            {
                if(stepHeadingHelpDiv.childNodes[i].getAttribute("class")=="helpColumn")
                {
                    stepHeadingHelpDiv.removeChild(stepHeadingHelpDiv.childNodes[i]);
                }
            }



            if(stepObject.options.length >= 3)
            {
                stepHeadingHelpDiv.style.width = '100%';
            } else if(stepObject.options.length == 2)
            {
                stepHeadingHelpDiv.style.width = '50%';
            }

            for(let i=0;i<stepObject.options.length;i++)
            {
                this.createHelpColumn(stepObject.options.length,i,stepHeadingHelpDiv,baseURL + this.productType + '/' + this.productType + '_' + stepObject.step_id + '_' + stepObject.options[i].option_id + '.jpg',stepObject.options[i].featureOptionUI,stepObject.options[i].featureOptionHelpText);
            }

            // Toggle visibility
            if (stepHeadingHelpDivBackground.classList.contains('displayNone')) {
                stepHeadingHelpDivBackground.setAttribute('class', 'stepHeadingHelpDivBackground displayBlock');
            } else if (stepHeadingHelpDivBackground.classList.contains('displayBlock')) {
                stepHeadingHelpDivBackground.setAttribute('class', 'stepHeadingHelpDivBackground displayNone');
            }
            document.body.setAttribute('style', 'overflow: hidden');
        });
        stepHeadingHelpDivBackground.appendChild(stepHeadingHelpDiv);
        stepContainer.appendChild(stepHeadingHelpDivBackground);
        return stepHeadingHelpDivBackground;
    }

    // Step option creation methods
    createStepOption(stepOptionObject, stepContainer, stepObject, isSwatch, selected, stepJSON) {

        const stepOption = {};
        stepOption.stepOptionContainer = this.createStepOptionContainer(stepOptionObject, stepContainer, isSwatch);
        stepOption.stepOptionRadioButton = this.createStepOptionRadioButton(stepOption.stepOptionContainer, stepObject.name);
        stepOption.stepOptionRadioButtonImage = this.createStepOptionRadioButtonImage(stepOption.stepOptionContainer, stepObject.name);
        stepOption.stepOptionLabel = this.createStepOptionLabel(stepOptionObject, stepOption.stepOptionContainer);
        stepOption.stepOptionPrice = this.createStepOptionPrice(stepOptionObject, stepOption.stepOptionContainer);
        stepOption.stepOptionIcon = this.createStepOptionIcon(stepOptionObject, stepOption.stepOptionContainer, stepObject, stepJSON);
        if (isSwatch) {
            if (stepJSON.subhead != '') {
                stepOption.swatchModal = this.createStepSwatchDetailsModal(stepContainer, stepObject.stepId, stepOptionObject.option_id, stepOptionObject);
            }
        }
        stepOption.key = stepOptionObject.key;
        if(selected)
        {
            stepObject.selectedOption = stepOption;
            stepOption.stepOptionRadioButton.checked = true;

            // Special case with table, wanted the subheader to update depending on Coffee/Side/Nested table selection
            if(stepObject.stepHeadingLabel.innerText == "Table Style"){
                $('.product-subheader').text(stepOptionObject.name);
            }

            stepOption.stepOptionContainer.classList.add('selectedOption');

            if (stepObject.stepCurrentSwatchHeading != null) {
                // stepOption.stepOptionContainer.classList.add('swatchContainerSelected');
                // console.log(stepObject);
                if (stepObject.stepSubheadingContainer != null) {
                    stepObject.stepCurrentSwatchHeading.innerText = stepOption.stepOptionLabel.innerText;
                    const iIcon = document.createElement('span');
                    iIcon.innerText = ' ⓘ';
                    iIcon.setAttribute('class', 'iIcon');
                    stepObject.stepCurrentSwatchHeading.appendChild(iIcon);
                    // here
                } else {
                    stepObject.stepCurrentSwatchHeading.innerText = stepOption.stepOptionLabel.innerText;
                }
            }
            stepObject.stepSelectionMobileOnly.label.innerText = stepOption.stepOptionLabel.innerText;
            stepObject.stepSelectionMobileOnly.image.setAttribute('src', stepOption.stepOptionIcon.src); 
        
        }
        else
        {
            stepOption.stepOptionRadioButton.checked = false;
            // stepOption.stepOptionContainer.classList.remove('swatchContainerSelected');
            stepOption.stepOptionContainer.classList.remove('selectedOption');

        }

        
        
        stepOption.stepOptionContainer.addEventListener(this.clickTouchEvent, () => {
            //console.log("click 3");
            // alert('3');
            $('.tooltip').hide();

            this.setSelectedOption(stepObject.stepId,stepOptionObject.option_id);

            const selectedStep = this.lookupStep(this.chairJSON,stepObject.stepId);

            
            if(selectedStep.reset_to_default)
            {
                //Forget selected values for certain steps with retain_selection=false  DG-10271
                for(let j=this.selections.length-1;j>=0;j--)
                {
                    if(this.selections[j].step_id==selectedStep.step_id)
                    {
                        continue;
                    }
                    const s = this.lookupStep(this.chairJSON,this.selections[j].step_id);
                    // console.log("retain:" + s.retain_selection);
                    if(!s.retain_selection)
                    {
                        console.log("removing selection:" + s.name + ":" + this.selections[j].option_id);
                        this.selections.splice(j,1);
                    }
                }            
            }
            

            this.start(this.chairJSON);

            
            stepObject.stepOptions.forEach((stepOption) => {
                stepObject.selectedOption = null;
                stepOption.stepOptionRadioButton.checked = false;
                if (stepObject.stepCurrentSwatchHeading != null) {
                    stepOption.stepOptionContainer.classList.remove('swatchContainerSelected');
                    stepObject.stepCurrentSwatchHeading.innerText = '';
                    // console.log(stepObject.selectedOption);
                }
            });

            stepObject.selectedOption = stepOption;
            stepOption.stepOptionRadioButton.checked = true;
            if (stepObject.stepCurrentSwatchHeading != null) {
                stepOption.stepOptionContainer.classList.add('swatchContainerSelected');
                stepObject.stepCurrentSwatchHeading.innerText = stepOption.stepOptionLabel.innerText;
                // here
                const iIcon = document.createElement('span');
                iIcon.innerText = ' ⓘ';
                iIcon.setAttribute('class', 'iIcon');
                stepObject.stepCurrentSwatchHeading.appendChild(iIcon);
            }
            // console.log$(stepObject).find('.currentStepSelectionMobileOnly');
            stepObject.stepSelectionMobileOnly.innerText = stepOption.stepOptionLabel.innerText;
            // console.log(stepObject.stepSelectionMobileOnly.innerText);
            
            
        });


     
        return stepOption;
    }
    


    createStepOptionContainer(optionObject, stepContainer, isSwatch) {
        // console.log(optionObject);
        const stepOptionContainer = document.createElement('div');
        stepOptionContainer.setAttribute('class', 'stepOption');
        stepOptionContainer.setAttribute('data-key', optionObject.sku_component);
        stepOptionContainer.setAttribute('data-option-id', optionObject.option_id);

        stepOptionContainer.setAttribute('data-mxt-render-product-ids', optionObject.render_product_ids);
        stepOptionContainer.setAttribute('data-mxt-render-step-types', optionObject.render_step_types);
        // console.log(optionObject);
        if (isSwatch) {
            stepOptionContainer.classList.add('swatchContainer');
            stepOptionContainer.setAttribute('data-toggle', 'tooltip');
            // TODO Link up with pricing data once added
            optionObject.optionPrice = '0';
            // if (optionObject.optionPrice != 0) {
            //     stepOptionContainer.setAttribute('title', optionObject.name + ' + ' + optionObject.optionPrice);
            // } else {
            //     stepOptionContainer.setAttribute('title', optionObject.name );
            // }
            stepOptionContainer.setAttribute('title', optionObject.name );
        }

        // TOOD: Link up with step option tooltip text once added
        const helpText = optionObject.stepOptionHelpText;
        if (helpText != null) {
            stepOptionContainer.setAttribute('data-toggle', 'tooltip');
            stepOptionContainer.setAttribute('data-placement', 'top');
            stepOptionContainer.setAttribute('title', optionObject.value.stepOptionHelpText);
        }
        stepContainer.appendChild(stepOptionContainer);
        return stepOptionContainer;
    }

    createStepOptionRadioButton(stepOptionContainer, stepId) {
        const stepOptionRadioButton = document.createElement('input');
        stepOptionRadioButton.setAttribute('type', 'radio');
        stepOptionRadioButton.setAttribute('class', 'customRadioButton');
        stepOptionRadioButton.setAttribute('name', stepId);
        stepOptionContainer.appendChild(stepOptionRadioButton);
        return stepOptionRadioButton;
    }

    createStepOptionRadioButtonImage(stepOptionContainer, stepId) {
        const stepOptionRadioButtonImage = document.createElement('img');
        stepOptionRadioButtonImage.setAttribute('class', 'customRadioButtonImage');
        stepOptionContainer.appendChild(stepOptionRadioButtonImage);
        return stepOptionRadioButtonImage;
    }

    createStepOptionLabel(stepOptionObject, stepOptionContainer) {
        const stepOptionLabel = document.createElement('label');
        stepOptionLabel.innerText = stepOptionObject.name;
        stepOptionLabel.setAttribute('class', 'customLabel');
        stepOptionContainer.appendChild(stepOptionLabel);

        const stepOptionLabelAccordion = document.createElement('label');
        stepOptionLabelAccordion.innerText = stepOptionObject.name;
        stepOptionLabelAccordion.setAttribute('class', 'customLabelAccordion');
        stepOptionContainer.appendChild(stepOptionLabelAccordion);
        return stepOptionLabel;
    }

    createStepOptionPrice(stepOptionObject, stepOptionContainer) {
        const stepOptionPrice = document.createElement('label');
        // TODO: Link up with step option price text once added
        stepOptionPrice.setAttribute('data-price', stepOptionObject.optionPrice);
        stepOptionPrice.setAttribute('class', 'stepOptionPrice');

        let priceString = '0';
        if (priceString.indexOf('-') > -1) {
            priceString = priceString.replace('-', '');
            stepOptionPrice.innerText = "- $" + priceString + ".00";
        } else {
            stepOptionPrice.innerText = "+ $" + priceString + ".00";
        }

        stepOptionContainer.appendChild(stepOptionPrice);
        // console.log(stepOptionObject.optionPrice);
        if (stepOptionPrice.innerText == "+ $0.00" || stepOptionPrice.innerText == "- $0.00") {
            stepOptionPrice.setAttribute('style', 'display: none;');
        }
        return stepOptionPrice;
    }

    createStepOptionIcon(stepOptionObject, stepOptionContainer, stepObject, stepJSON) {

        const stepOptionIcon = document.createElement('img');

        // This is bad and our webpack config needs to be properly fixed
        const baseURL = './';

        // console.log("create step optoion:" + baseURL + this.productType + '/' + this.productType + '_' + stepObject.stepId + '_' + stepOptionObject.option_id + '.jpg');
        stepOptionIcon.setAttribute('src', baseURL + this.productType + '/' + this.productType + '_' + stepObject.stepId + '_' + stepOptionObject.option_id + '.jpg');
        stepOptionIcon.setAttribute('class', 'stepOptionThumbnail');
        stepOptionContainer.appendChild(stepOptionIcon);
        return stepOptionIcon;
    }

    selectDefaultStepOptions(defaultValue, stepToSelect, stepObject) {
        let defaultFound = false;
        stepToSelect.stepOptions.forEach((stepOption) => {
            if (stepOption.key == defaultValue) {
                stepOption.stepOptionRadioButton.checked = true;
                if (stepObject.stepCurrentSwatchHeading != null) {
                    stepOption.stepOptionContainer.classList.add('swatchContainerSelected');
                    stepObject.stepCurrentSwatchHeading.innerText = stepOption.stepOptionLabel.innerText;
                }
                defaultFound = true;
                stepToSelect.selectedOption = stepOption;
            } else {
                stepOption.stepOptionRadioButton.checked = false;
            }
        });
        if (defaultFound == false) {
            // stepToSelect.stepOptions[0].stepOptionRadioButton.checked = true;
            // stepToSelect.stepOptions[0].stepOptionContainer.classList.add('swatchContainerSelected');
        }
    }

    removeDashesFromSku(sku) {
        const newSku = sku.replace(/\s/g, '-');
        return newSku;
    }


    setPriceAnimation(priceToAnimate) {
        if (this.priceChangeAnimation != null) {
            clearTimeout(this.priceChangeAnimation);
        }
            document.getElementById('price-change').innerText = priceToAnimate;
            document.getElementById('price-change').setAttribute('style', 'display:block;');
            this.priceChangeAnimation = setTimeout(() => {
                document.getElementById('price-change').setAttribute('style', 'display:none;');
            }, 1750);

    }

    setTotalPrice(totalPrice) {
        
        document.getElementById('inline-price').innerText = '$' + totalPrice + '.00';
        document.getElementById('footer-price').innerText = '$' + totalPrice + '.00';
        this.previousPrice = this.newTotalPrice;
        this.newTotalPrice = totalPrice;
    }

    setBasePrice(basePrice) {
        $("#basePrice").text(basePrice);
    }

    

    removeExtraCharactersFromPrice(price) {
        price = price.replace(' ', '');
        price = price.replace(' ', '');
        price = price.replace('$', '');
        price = price.replace('.00', '');

        return price;
    }
}