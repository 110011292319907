
import {MxtglTF} from './mxt-gltf';
import LoadingIcon from './../assets/images/icons/loader.svg';

export class DCOtoglTFChairsStools {
    
    constructor(mxtglTFArray) {

        this.mxtglTFArray = mxtglTFArray;

        //
        // TODO: This needs to be configurable.
        //this.urlBase = 'https://s3.amazonaws.com/vc-gltf/develop/000030/';
        //this.urlBase = 'https://s3.amazonaws.com/vc-gltf/staging/000030/';
        // this.urlBase = 'https://s3.amazonaws.com/vc-gltf/production/000030/';
        this.urlBase = '/';

        this.urlMaterialsChairStoolFrame      = this.urlBase + 'assets/gltfs/Frame/Frame.gltf';
        this.urlMaterialsChairStoolSuspension = this.urlBase + 'assets/gltfs/Seat/Seat.gltf';

        this.productIDsToglTFs = { };
        this.productIDsToglTFs['HRMCOSM-CHAIR'] = this.urlBase + 'assets/gltfs/Frame/Frame.gltf';
        this.productIDsToglTFs['HRMCOSM-STOOL'] = this.urlBase + 'stools/HRMCOSM_STOOL/HRMCOSM_STOOL.gltf';
    }

    _loadChair(mxtglTF, urlChairStool, theDCOs) {
        return new Promise((resolve, reject)=> {
            mxtglTF.loadChairStool(urlChairStool, this.urlMaterialsChairStoolFrame, this.urlMaterialsChairStoolSuspension, () => {

                for(let dco of theDCOs)
                {
                    if(dco.stepType=="Chair")
                    {
                        if(dco.productID=="Chair")
                        {
                            for(let child of mxtglTF.chairHeightExtenderStandard)
                            {
                                child.visible = true;
                            }
                        }
                        else
                        {
                            //Stool
                            for(let child of mxtglTF.chairHeightExtenderStool)
                            {
                                child.visible = true;
                            }
                        }

                        for(let child of mxtglTF.chairStitches)
                        {
                            child.visible = true;
                        }
                    }
                    else if(dco.stepType=="Arms")
                    {
                        if(dco.productID=="4D")
                        {
                            for(let child of mxtglTF.armRest4D)
                            {
                                child.visible = true;
                            }
                        }
                        else if(dco.productID=="HeightAdjustable")
                        {
                            for(let child of mxtglTF.armRestHeightAdjustable)
                            {
                                child.visible = true;
                            }
                        }
                        else if(dco.productID=="Fixed")
                        {
                            for(let child of mxtglTF.armRestFixed)
                            {
                                child.visible = true;
                            }
                        }
                    }
                    else if(dco.stepType=="Back")
                    {
                        if(dco.productID=="Back")
                        {
                            for(let child of mxtglTF.chairKnitBack)
                            {
                                child.visible = true;
                            }
                        }
                    }
                    else if(dco.stepType=="Frame")
                    {
                        for(let child of mxtglTF.chairFrame)
                        {
                            child.visible = true;
                        }

                        mxtglTF.chairFrameMaterialNode.material = mxtglTF.materials.get(dco.productID);

                        if(dco.productID=="Frame_Black")
                        {
                            mxtglTF.armRest4DMaterialNode.material = mxtglTF.materials.get("ArmRest_Adjustable_Black");
                            mxtglTF.armRestHeightAdjustableMaterialNode.material = mxtglTF.materials.get("ArmRest_Adjustable_Black");
                            mxtglTF.armRestFixedMaterialNode.material = mxtglTF.materials.get("ArmRest_Fixed_Black");

                            mxtglTF.chairLumbarMaterialNode.material = mxtglTF.materials.get("Lumbar_Black");
                        }
                        else if(dco.productID=="Frame_Titanium")
                        {
                            mxtglTF.armRest4DMaterialNode.material = mxtglTF.materials.get("ArmRest_Adjustable_Titanium");
                            mxtglTF.armRestHeightAdjustableMaterialNode.material = mxtglTF.materials.get("ArmRest_Adjustable_Titanium");
                            mxtglTF.armRestFixedMaterialNode.material = mxtglTF.materials.get("ArmRest_Fixed_Titanium");

                            mxtglTF.chairLumbarMaterialNode.material = mxtglTF.materials.get("Lumbar_Titanium");
                        }
                    }
                    else if(dco.stepType=="Base Color")
                    {
                        for(let child of mxtglTF.chairBase)
                        {
                            child.visible = true;
                        }

                        mxtglTF.chairBaseMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                    else if(dco.stepType=="Back Carrier Color")
                    {
                        for(let child of mxtglTF.chairCarrier)
                        {
                            child.visible = true;
                        }

                        mxtglTF.chairCarrierMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                    else if(dco.stepType=="Lumbar")
                    {
                        if(dco.productID=="Lumbar")
                        {
                            for(let child of mxtglTF.chairLumbar)
                            {
                                child.visible = true;
                            }
                        }

                        // mxtglTF.chairCarrierMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                    else if(dco.stepType=="Cylinder")
                    {
                        // for(let child of mxtglTF.chairCylinder)
                        // {
                        //     child.visible = true;
                        // }

                        // mxtglTF.chairCylinderMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                    else if(dco.stepType=="Casters")
                    {
                        for(let child of mxtglTF.chairCaster)
                        {
                            child.visible = true;
                        }

                        mxtglTF.chairCasterMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                    else if(dco.stepType=="Knit")
                    {

                        mxtglTF.chairKnitBackMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                    else if(dco.stepType=="Upholstery")
                    {

                        for(let child of mxtglTF.chairSeat)
                        {
                            child.visible = true;
                        }
                        mxtglTF.chairSeatMaterialNode.material = mxtglTF.materials.get(dco.productID);

                        mxtglTF.chairStitchesMaterialNode.material = mxtglTF.materials.get(dco.productID);
                    }
                }


                //
                // Geometry DCOs

                // const dcoHeightAdjustment = this._findDCOByStepType('Height Adjustment', theDCOs);
                // if (dcoHeightAdjustment != null) {
                //     if (dcoHeightAdjustment.productID == 'HRMCOSM-HEIGHT-STANDARD-LOW') {
                //         mxtglTF.setVisibilityChairStoolHeightAdjustment('HRMCOSM-HEIGHT-STANDARD');
                //     } else {
                //         mxtglTF.setVisibilityChairStoolHeightAdjustment(dcoHeightAdjustment.productID);
                //     }
                // }

                // const dcoArms = this._findDCOByStepType('Arms', theDCOs);
                // if (dcoArms != null) {
                //     mxtglTF.setVisibilityChairStoolArm(dcoArms.productID);
                // }

                // const dcoFrameBack = this._findDCOByStepType('Frame Back', theDCOs);
                // if (dcoFrameBack != null) {
                //     mxtglTF.setVisibilityChairStoolBack(dcoFrameBack.productID);
                // }

                // const dcoCasters = this._findDCOByStepType('Casters', theDCOs);
                // if (dcoCasters != null) {
                //     if (dcoCasters.productID == 'HRMCOSM-CASTER-QUIET-ROLL-2') {
                //         mxtglTF.setVisibilityChairStoolCasters('HRMCOSM-CASTER-QUIET-ROLL');
                //     } else {
                //         mxtglTF.setVisibilityChairStoolCasters(dcoCasters.productID);
                //     }
                // }

                // mxtglTF.setVisibilityChairStoolBase('HRMCOSM-BASE-PLASTIC');

                // //
                // // Material DCOs

                // const dcoSuspensionCover = this._findDCOByStepType('Frame Back Suspension Cover', theDCOs);
                // if (dcoSuspensionCover != null) {
                //     mxtglTF.setChairStoolMaterialSuspension(dcoSuspensionCover.productID);

                //     //
                //     // The trims are linked to the suspension covers.

                //     const materialTrim = dcoSuspensionCover.productID.replace('UPHOLSTERY', 'BASE');
                //     mxtglTF.setChairStoolMaterialTrims(materialTrim);
                // }

                // const dcoArmFrameCover = this._findDCOByStepType('Arm Frame Cover', theDCOs);
                // if (dcoArmFrameCover != null) {
                //     const materialArms = dcoArmFrameCover.productID.replace('FRAME', 'ARMS');
                //     mxtglTF.setChairStoolMaterialArms(materialArms);
                // }
                
                // const dcoArmSupportFrameCover = this._findDCOByStepType('Arm Support Frame Cover', theDCOs);
                // if (dcoArmSupportFrameCover != null) {
                //     if (dcoArmSupportFrameCover.productID == 'HRMCHAIR-FRAME-CARBON') {
                //         mxtglTF.setChairStoolMaterialArmPads('HRMCHAIR-ARMS-CARBON');
                //     } else if (dcoArmSupportFrameCover.productID == 'HRMCHAIR-ARMPAD-BLACK') {
                //         mxtglTF.setChairStoolMaterialArmPads('HRMCHAIR-ARMS-BLACK');
                //     } else if (dcoArmSupportFrameCover.productID == 'HRMCHAIR-FRAME-MINERAL') {
                //         mxtglTF.setChairStoolMaterialArmPads('HRMCHAIR-ARMS-MINERAL');
                //     }
                // }

                // const dcoFrameBackCover = this._findDCOByStepType('Frame Back Cover', theDCOs);
                // if (dcoFrameBackCover != null) {

                //     let replaceString = 'HIGH';
                //     if (dcoFrameBack != null) {
                //         if (dcoFrameBack.productID.includes('LOW')) {
                //             replaceString = 'LOW';
                //         } else if (dcoFrameBack.productID.includes('MEDIUM')) {
                //             replaceString = 'MED';
                //         }
                //     }

                //     const materialFrameBack = dcoFrameBackCover.productID.replace('FRAME', 'BACK-' + replaceString);
                //     mxtglTF.setChairStoolMaterialBack(materialFrameBack);
                // }

                // const dcoFrameChassisCover = this._findDCOByStepType('Frame Chassis Cover', theDCOs);
                // if (dcoFrameChassisCover != null) {
                //     let materialFrameChassisCover = dcoFrameChassisCover.productID.replace('FRAME', 'CHASSIS');
                //     materialFrameChassisCover = materialFrameChassisCover.replace('BASE', 'CHASSIS');
                //     mxtglTF.setChairStoolMaterialChassis(materialFrameChassisCover);
                // }

                // const dcoCastersCover = this._findDCOByStepType('Casters Cover', theDCOs);
                // if (dcoCastersCover != null) {

                //     if (dcoCastersCover.productID == 'HRMCHAIR-BASE-SEMI-POLISHED') {
                //         mxtglTF.setVisibilityChairStoolBase('HRMCOSM-BASE-SEMI-POLISHED');
                //     }

                //     const materialFrameChassisCover = dcoCastersCover.productID.replace('FRAME', 'BASE');
                //     mxtglTF.setChairStoolMaterialBase(materialFrameChassisCover);
                // }

                // const dcoCastersFrameCover = this._findDCOByStepType('Caster Frame Cover', theDCOs);
                // if (dcoCastersFrameCover != null) {
                //     if (dcoCastersFrameCover.productID == 'HRMCHAIR-FRAME-CASTER-DEFAULT') {
                //         mxtglTF.setChairStoolMaterialCasters('HRMCHAIR-BLACK-METAL');
                //     } else {
                //         const materialCastersFrameCover = dcoCastersFrameCover.productID.replace('FRAME', 'CASTER');
                //         mxtglTF.setChairStoolMaterialCasters(materialCastersFrameCover);
                //     }
                // }

                resolve();

            }, (theError) => {
                reject(theError);
            });
        });
    }

    renderglTF(theProductID, theDefaultChildOverrides) {

        // console.log("renderGLTF:" + theProductID + ":" + theDefaultChildOverrides);
        for(let i = 0; i < this.mxtglTFArray.length; ++i) {
            this.mxtglTFArray[i].setBackgroundChair();

            if (i == 1) {
                this.mxtglTFArray[i].setBackgroundFullscreen();
            }
        }

        const theDCOs = JSON.parse(theDefaultChildOverrides);
        // if (theProductID in this.productIDsToglTFs) {

            this._showLoadingElements();

            const urlChairStool = this.productIDsToglTFs[theProductID];

            const promises = [];
                for(let i = 0; i < this.mxtglTFArray.length; ++i) {
                    promises.push(this._loadChair(this.mxtglTFArray[i], urlChairStool, theDCOs));
                }
                Promise.all(promises).then(()=> {
                    this._hideLoadingElements();
                }, theError=> {
                    console.log("Error: " + theError);
                    for(let i = 0; i < this.mxtglTFArray.length; ++i) {
                        this.mxtglTFArray[i].clearScene();
                    }
                    this._hideLoadingElements();
                });
            
        // } else {

        //     console.log('Unable to find chair / stool glTF for ' + dcoTableFramePrimary.productID);
        //     for(let i = 0; i < this.mxtglTFArray.length; ++i) {
        //         this.mxtglTFArray[i].clearScene();
        //     }
        // }
    }

    //
    // Helpers

    _findDCOByStepType(theStepType, theDCOs) {

        let result = null;

        theDCOs.forEach(function(theDCO) {
            if (theDCO.stepType == theStepType) {
                result = theDCO;
            }
        });

        return result;
    }

    _showLoadingElements() {

        const theLoader = document.getElementById('imageSpinsLoader');
        if (theLoader != null) {
            theLoader.style.visibility = "visible";
        }

        const imageSpinsLoaderElement = document.getElementById("imageSpinsLoader");
        if (imageSpinsLoaderElement != null) {
            imageSpinsLoaderElement.src = LoadingIcon;
            imageSpinsLoaderElement.style.visibility = "visible";
        }
        
        const imageSpinsPlaceholderElement = document.getElementById("imageSpinsPlaceholder");
        if (imageSpinsPlaceholderElement != null) {
            imageSpinsPlaceholderElement.src = null;
            imageSpinsPlaceholderElement.style.visibility = "hidden";
        }
        
        const imageSpins = document.getElementById("imageSpins");
        if (imageSpins != null) {
            imageSpins.style.visibility = "hidden";
        }
        
        const imageSpinsReel = document.getElementById("imageSpins-reel");
        if (imageSpinsReel != null) {
            imageSpinsReel.style.visibility = "hidden";
        }
        
        const fullScreenButton = document.getElementById('spins-button-fullscreen');
        if (fullScreenButton != null) {
            fullScreenButton.style.opacity = 0.5;
            fullScreenButton.disabled = true;
        }
    
        const rightButton = document.getElementById('spins-button-right');
        if (rightButton != null) {
            rightButton.style.opacity = 0.5;
            rightButton.disabled = true;
        }
    
        const leftButton = document.getElementById('spins-button-left');
        if (leftButton != null) {
            leftButton.style.opacity = 0.5;
            leftButton.disabled = true;
        }
    
        const extraButtons = document.getElementById('spinExtraButtonsContainer');
        if (extraButtons != null) {
            extraButtons.style.opacity = 0.5;
            
            const downloadButton = document.getElementById('spinsDownloadButton');
            if (downloadButton != null) {
                downloadButton.disabled = true;
            }
    
            const copyShareLinkTrigger = document.getElementById('copyShareLinkTrigger');
            if (copyShareLinkTrigger != null) {
                copyShareLinkTrigger.disabled = true;
            }
        }
    }
    
    _hideLoadingElements() {
    
        const theLoader = document.getElementById('imageSpinsLoader');
        if (theLoader != null) {
            theLoader.style.visibility = "hidden";
        }
        
        const imageSpinsLoaderElement = document.getElementById("imageSpinsLoader");
        if (imageSpinsLoaderElement != null) {
            imageSpinsLoaderElement.src = LoadingIcon;
            imageSpinsLoaderElement.style.visibility = "hidden";
        }
        
        const imageSpinsPlaceholderElement = document.getElementById("imageSpinsPlaceholder");
        if (imageSpinsPlaceholderElement != null) {
            imageSpinsPlaceholderElement.src = null;
            imageSpinsPlaceholderElement.style.visibility = "hidden";
        }
    
        const imageSpins = document.getElementById("imageSpins");
        if (imageSpins != null) {
            imageSpins.style.visibility = "visible";
        }
        
        const imageSpinsReel = document.getElementById("imageSpins-reel");
        if (imageSpinsReel != null) {
            imageSpinsReel.style.visibility = "visible";
        }
        
        const fullScreenButton = document.getElementById('spins-button-fullscreen');
        if (fullScreenButton != null) {
            fullScreenButton.style.opacity = 1.0;
            fullScreenButton.disabled = false;
        }
    
        const rightButton = document.getElementById('spins-button-right');
        if (rightButton != null) {
            rightButton.style.opacity = 1.0;
            rightButton.disabled = false;
        }
    
        const leftButton = document.getElementById('spins-button-left');
        if (leftButton != null) {
            leftButton.style.opacity = 1.0;
            leftButton.disabled = false;
        }
    
        const extraButtons = document.getElementById('spinExtraButtonsContainer');
        if (extraButtons != null) {
            extraButtons.style.opacity = 1.0;
    
            const downloadButton = document.getElementById('spinsDownloadButton');
            if (downloadButton != null) {
                downloadButton.disabled = false;
            }
    
            const copyShareLinkTrigger = document.getElementById('copyShareLinkTrigger');
            if (copyShareLinkTrigger != null) {
                copyShareLinkTrigger.disabled = false;
            }
        }
    }
}
