
import {MxtglTF} from './mxt-gltf';
import {DCOtoglTFChairsStools} from './DCOtoglTFChairsStools';
import {DCOtoglTFTables} from './DCOtoglTFTables';

import ArrowLeftIcon from '../mxt360spinsviewer/assets/images/icons/arrow-left.svg';
import ArrowRightIcon from '../mxt360spinsviewer/assets/images/icons/arrow-right.svg';
import FullscreenIcon from '../mxt360spinsviewer/assets/images/icons/fullscreen.svg';
import SpinIcon from '../mxt360spinsviewer/assets/images/icons/3d-circle.svg';

export class DCOtoglTF {
    
    constructor() {

        const that = this;

        //
        // Create the glTF context.

        this.iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1;

        this.clickTouchEvent = this.iOS || isAndroid ? "touchend" : "click";
        this.mousedownTouchEvent = this.iOS || isAndroid ? "touchstart" : "mousedown";
        this.mouseupTouchEvent = this.iOS || isAndroid ? "touchend" : "mouseup";
        this.mouseoutTouchEvent = this.iOS || isAndroid ? "touchend" : "mouseout";

        this.wasRenderingChairStool = false;
        this.wasRenderingTable = false;

        this.ASPECT = 0.81;

        this.mxtglTF = null;
        const parentglTF = document.getElementById("divSpins");
        if (parentglTF != null) {

            const onResize = (renderer, camera) => {
                let width = parentglTF.offsetWidth;
                let height = parentglTF.offsetHeight;

                height = width / that.ASPECT;
        
                renderer.setSize(width, height);
        
                camera.aspect = that.ASPECT;
                camera.updateProjectionMatrix();
            };

            this.mxtglTF = new MxtglTF(parentglTF, onResize);

            //
            // Setup a default scene.

            this.mxtglTF.clearScene();
            this.mxtglTF.setCameraPosition(0.0, 0.0, 3.0);
        }

        const onResizeFullscreen = (renderer, camera) => {
            let width = document.getElementById('gltf-spins-modal').offsetWidth;
            let height = document.getElementById('gltf-spins-modal').offsetHeight;

            height = height - 40;

            renderer.setSize(width, height);
    
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        this._fullscreenMxtglTF = new MxtglTF(document.getElementById('gltf-spins-modal'), onResizeFullscreen);
        this._fullscreenMxtglTF.clearScene();
        this._fullscreenMxtglTF.setCameraPosition(0.0, 0.0, 3.0);

        //
        // Create the business rules classes.

        this.businessRulesChairsStools = new DCOtoglTFChairsStools([this.mxtglTF, this._fullscreenMxtglTF], this._fullscreenMxtglTF);
        this.businessRulesTables = new DCOtoglTFTables([this.mxtglTF, this._fullscreenMxtglTF], this._fullscreenMxtglTF);

        //
        // Create the extra UI elements.


        const spinsImage = document.createElement('img');
        spinsImage.setAttribute('src', SpinIcon);
        spinsImage.setAttribute('id', 'spinCircle');
        spinsImage.setAttribute('draggable', 'false');
        parentglTF.append(spinsImage);
        
        const buttonsContainer = document.createElement('div');
        buttonsContainer.id = "spinsButtonContainer";
        parentglTF.appendChild(buttonsContainer);

        

        const buttonsContainerInner = document.createElement('div');
        buttonsContainerInner.id = "spinsButtonContainerInner";
        buttonsContainer.appendChild(buttonsContainerInner);

        const leftButton = document.createElement('button');
        leftButton.id = "spins-button-left";
        leftButton.classList.add('image-spins-button');
        leftButton.style.opacity = 0.5;

        const leftButtonIcon = document.createElement('img');
        leftButtonIcon.setAttribute('src', ArrowLeftIcon);
        leftButtonIcon.setAttribute('class', 'spinButtonArrow');
        leftButtonIcon.setAttribute('draggable', 'false');
        leftButton.appendChild(leftButtonIcon);
        
        buttonsContainerInner.appendChild(leftButton);

        this.fullscreenButton = document.createElement('button');
        this.fullscreenButton.id = "spins-button-fullscreen";
        this.fullscreenButton.classList.add('image-spins-button');
        this.fullscreenButton.style.opacity = 0.5;

        this.fullscreenButtonIcon = document.createElement('img');
        this.fullscreenButtonIcon.setAttribute('src', FullscreenIcon);
        this.fullscreenButtonIcon.setAttribute('class', 'spinButtonArrow');
        this.fullscreenButtonIcon.setAttribute('draggable', 'false');
        this.fullscreenButton.appendChild(this.fullscreenButtonIcon);
        buttonsContainerInner.appendChild(this.fullscreenButton);

        this.fullscreenButton.addEventListener(this.clickTouchEvent, e=> {            
            document.getElementById('div-spins-modal').style.display = 'block';
            this._fullscreenMxtglTF.onWindowResize();
        });

        document.getElementById('span-spins-modal').addEventListener(this.clickTouchEvent, e=> {
            document.getElementById('div-spins-modal').style.display = 'none';
        });

        const rightButton = document.createElement('button');
        rightButton.id = "spins-button-right";
        rightButton.classList.add('image-spins-button');
        rightButton.style.opacity = 0.5;

        const rightButtonIcon = document.createElement('img');
        rightButtonIcon.setAttribute('src', ArrowRightIcon);
        rightButtonIcon.setAttribute('class', 'spinButtonArrow');
        rightButtonIcon.setAttribute('draggable', 'false');
        rightButton.appendChild(rightButtonIcon);
        buttonsContainerInner.appendChild(rightButton);

        const extraSpinButtons = document.createElement('div');
        extraSpinButtons.setAttribute('id', 'spinExtraButtonsContainer');
        extraSpinButtons.setAttribute('class', 'spinExtraButtonsContainer');
        extraSpinButtons.style.opacity = 0.5;

        buttonsContainer.appendChild(extraSpinButtons);

        const downloadImageButton = document.createElement('button');
        downloadImageButton.innerHTML = '<svg class="iconFillColor"  fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="image___pLjgk" style="vertical-align: middle; width: 18px; height: 18px; margin-right: 8px; fill: #0073ce;"><defs><path id="a" d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></defs><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="b" fill="#fff"><use xlink:href="#a"></use></mask><g class="iconFillColor" mask="url(#b)"><path d="M0 0h24v24H0z"></path></g></g></svg> Download Image';
        downloadImageButton.setAttribute('id', 'spinsDownloadButton');
        downloadImageButton.setAttribute('class', 'spinExtraButton');
        
        const downloadAnchor = document.createElement('a');
        downloadAnchor.setAttribute('class', 'displayNone');

        extraSpinButtons.append(downloadAnchor);
        extraSpinButtons.append(downloadImageButton);

        downloadImageButton.addEventListener(this.clickTouchEvent, () => {

            // Without 'preserveDrawingBuffer' set to true, we must render now
            downloadAnchor.href = this.mxtglTF.getScreenshotUrl();
            downloadAnchor.download = document.getElementById('sku').innerText + '.png';
            downloadAnchor.click();
        });

        const copyLinkButtonContainer = document.createElement('div');
        copyLinkButtonContainer.setAttribute('class', 'copyLinkButtonContainer');
        extraSpinButtons.appendChild(copyLinkButtonContainer);

        const copyLinkButton = document.createElement('button');
        copyLinkButton.innerHTML = '<svg class="iconFillColor" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="image___pLjgk" style="vertical-align: middle; width: 20px; height: 20px; margin-right: 8px; fill: #0073ce;"><path d="M0 0h24v24H0z" fill="none"></path><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path></svg> Copy Share Link';
        copyLinkButton.setAttribute('id', 'copyShareLinkTrigger');
        copyLinkButton.setAttribute('class', 'spinExtraButton');
        copyLinkButton.addEventListener(this.clickTouchEvent, () => {
            document.getElementById('copyLinkContainer').setAttribute('style', 'display: block;');
            const URL = window.location.href;
            const input = document.getElementById('copyLinkInput');
            input.value = URL;
            copyLinkButtonTwo.innerHTML = 'Copy Link';
        });
        copyLinkButtonContainer.appendChild(copyLinkButton);

        const copyLinkContainer = document.createElement('div');
        copyLinkContainer.id = 'copyLinkContainer';
        copyLinkContainer.setAttribute('style', 'display:none;');
        copyLinkButtonContainer.appendChild(copyLinkContainer);

        const copyLinkCloseButton = document.createElement('button');
        copyLinkCloseButton.innerHTML = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 24 24" class="icon___2lAjD" style="vertical-align: middle; cursor: pointer"><path id="CloseA" stroke="black" stroke-width="2" d="M20 4.38L18.619 3 11.5 10.12 4.381 3 3 4.38l7.12 7.12L3 18.62 4.381 20l7.12-7.12L18.618 20 20 18.62l-7.12-7.12z"></path><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="CloseB" fill="#fff"><use xlink:href="#CloseA"></use></mask><g fill="none" mask="url(#CloseB)"><path d="M0 0h24v24H0z"></path></g></g></svg>';
        copyLinkCloseButton.id = 'copyLinkCloseButton';
        copyLinkCloseButton.addEventListener(this.clickTouchEvent, () => {
            copyLinkContainer.setAttribute('style', 'display: none;');
        });
        copyLinkContainer.appendChild(copyLinkCloseButton);

        const copyLinkHeading = document.createElement('p');
        copyLinkHeading.innerText = 'Share Configuration';
        copyLinkHeading.id = 'copyLinkHeading';
        copyLinkContainer.appendChild(copyLinkHeading);

        const copyLinkInputContainer = document.createElement('div');
        copyLinkInputContainer.id = 'copyLinkInputContainer';
        copyLinkContainer.appendChild(copyLinkInputContainer);
    
        const copyLinkInput = document.createElement('input');
        copyLinkInput.setAttribute('type', 'text');
        copyLinkInput.id = 'copyLinkInput';
        copyLinkInputContainer.appendChild(copyLinkInput);

        const copyLinkButtonOne = document.createElement('button');
        copyLinkButtonOne.id = 'copyLinkButtonOne';
        copyLinkButtonOne.innerHTML = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" class="image___pLjgk"><path d="M0 0h24v24H0z" fill="none"></path><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path></svg>';
        copyLinkButtonOne.addEventListener(this.clickTouchEvent, ()=> {
            copyLinkInput.select();
            document.execCommand("copy");
            copyLinkButtonTwo.innerHTML = '<img id="copyChecked"> Copied To Clipboard';
        });
        copyLinkInputContainer.append(copyLinkButtonOne);

        const copyLinkButtonTwo = document.createElement('button');
        copyLinkButtonTwo.id = 'copyLinkButtonTwo';
        copyLinkButtonTwo.innerHTML = 'Copy Link';
        copyLinkButtonTwo.addEventListener(this.clickTouchEvent, () => {
            copyLinkInput.select();
            if(that.iOS)
            {
                that.iosCopyToClipboard(copyLinkInput);
            }
            else
            {
                document.execCommand("copy");
            }
            copyLinkButtonTwo.innerHTML = '<img id="copyChecked"> Copied To Clipboard';
        });
        copyLinkContainer.append(copyLinkButtonTwo);

        //
        // Implement the button callbacks.

        leftButton.addEventListener(this.mousedownTouchEvent, () => {
            
            const deltaRadians = Math.PI / 10.0;
            this.mxtglTF.incrementCameraRotationY(deltaRadians);

            this.spinLeftButtonHold = setInterval(() => {
                this.mxtglTF.incrementCameraRotationY(deltaRadians);
            }, 150);
        });

        leftButton.addEventListener(this.mouseupTouchEvent, () => {
            clearInterval(this.spinLeftButtonHold);
        });

        leftButton.addEventListener(this.mouseoutTouchEvent, () => {
            clearInterval(this.spinLeftButtonHold);
        });

        rightButton.addEventListener(this.mousedownTouchEvent, () => {
            
            const deltaRadians = -Math.PI / 10.0;
            this.mxtglTF.incrementCameraRotationY(deltaRadians);

            this.spinRightButtonHold = setInterval(() => {
                this.mxtglTF.incrementCameraRotationY(deltaRadians);
            }, 150);
        });

        rightButton.addEventListener(this.mouseupTouchEvent, () => {
            clearInterval(this.spinRightButtonHold);
        });

        rightButton.addEventListener(this.mouseupTouchEvent, () => {
            clearInterval(this.spinRightButtonHold);
        });

        document.getElementById('button-spins-modal-zoom-in').addEventListener(this.mousedownTouchEvent, e=> {
            this._fullscreenMxtglTF.zoomIn();
            this.modalZoomInHold = setInterval(function() {
                this._fullscreenMxtglTF.zoomIn();
            },150);
        });
        document.getElementById('button-spins-modal-zoom-in').addEventListener(this.mouseupTouchEvent, () => {
            clearInterval(this.modalZoomInHold);
        });
        document.getElementById('button-spins-modal-zoom-in').addEventListener(this.mouseoutTouchEvent, () => {
            clearInterval(this.modalZoomInHold);
        });
        document.getElementById('button-spins-modal-zoom-out').addEventListener(this.mousedownTouchEvent, e=> {
            this._fullscreenMxtglTF.zoomOut();
            this.modalZoomOutHold = setInterval(function() {
                this._fullscreenMxtglTF.zoomOut();
            },150);
        });
        document.getElementById('button-spins-modal-zoom-out').addEventListener(this.mouseupTouchEvent, () => {
            clearInterval(this.modalZoomOutHold);
        });
        document.getElementById('button-spins-modal-zoom-out').addEventListener(this.mouseoutTouchEvent, () => {
            clearInterval(this.modalZoomOutHold);
        });
    }

    iosCopyToClipboard(el) {
        var oldContentEditable = el.contentEditable,
            oldReadOnly = el.readOnly,
            range = document.createRange();
    
        el.contentEditable = true;
        el.readOnly = false;
        range.selectNodeContents(el);
    
        var s = window.getSelection();
        s.removeAllRanges();
        s.addRange(range);
    
        el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
    
        el.contentEditable = oldContentEditable;
        el.readOnly = oldReadOnly;
    
        document.execCommand('copy');
    }

    renderglTF(theProductID, theDefaultChildOverrides, fieldOfView, cameraTargetOffsetY, cameraOffsetY, cameraoffsetZ) {

            //fov
            // fieldOfView = 15;
            // //height
            // cameraTargetOffsetY = 0.49;
            // //depth
            // cameraoffsetZ = 1.3;

            const cameraVerticalPosition = 0.9;
            this.mxtglTF.setCameraTarget(0.0, cameraTargetOffsetY, 0.0);
            this._fullscreenMxtglTF.setCameraTarget(0.0, cameraTargetOffsetY, 0.0);

            const cameraPosition = this.mxtglTF.getCameraPosition();
            this.mxtglTF.setCameraPosition(cameraPosition[0], cameraVerticalPosition, cameraPosition[2]);
            this._fullscreenMxtglTF.setCameraPosition(cameraPosition[0], cameraVerticalPosition, cameraPosition[2]);

            this.mxtglTF.setCameraFOV(fieldOfView);
            this._fullscreenMxtglTF.setCameraFOV(fieldOfView);

            this.mxtglTF.setCameraZoomMinMax(cameraoffsetZ, cameraoffsetZ);
            this._fullscreenMxtglTF.setCameraZoomMinMax(0.1, 5.0);

            this.mxtglTF.disableCameraVerticalRotation();

        if (theProductID == 'HNI') {

            //
            // TODO: Make more robust later.
            if (!this.wasRenderingChairStool) {
                this.mxtglTF.setCameraPosition(-0.48060660463141613, cameraVerticalPosition, 1.1984671054509681);
                this._fullscreenMxtglTF.setCameraPosition(-0.48060660463141613, cameraVerticalPosition, 1.1984671054509681);
            }

            this.wasRenderingChairStool = true;
            this.wasRenderingTable = false;
            this.businessRulesChairsStools.renderglTF(theProductID, theDefaultChildOverrides);
        } else if (theProductID == 'HRMAL-TABLE') {

            this.wasRenderingChairStool = false;
            this.wasRenderingTable = true;
            this.businessRulesTables.renderglTF(theProductID, theDefaultChildOverrides);
        } else {
            console.log('Unknown Product ID: ' + theProductID);
        }
    }
}
